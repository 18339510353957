import React, { useCallback, useEffect, useMemo, useState } from "react";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import { Button } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { CHeading } from "../../CHeading";
import SwalAlert from "../../common/SwalAlert";
import Loading from "../../common/Loading";
import Toast from "../../common/Toast";
import EmailSettingModal from "../Models/EmailSettingModal";

const EmailSetting = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [EmailGroupRowData, setEmailGroupRowData] = useState([]);
  const [editEmailGroupData, setEditEmailGroupData] = useState([]);
  const [refresh, setrefresh] = useState(0);
  const [show, setShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [message, setMessage] = useState("");
  const [toastKey, setToastKey] = useState<number>(0);

  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };


  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    async function populateGroupEmails() {
      setLoading(true);
      await getApi("/api/Common/GetGroupEmails")
        .then((response) => {
          
          setEmailGroupRowData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateGroupEmails();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  const columnDefs = [
    {
      field: "",
      maxWidth: 80,
      cellRenderer: (data: any) => (
        <div>
          <Button
            size="sm"
            className="btn btn-info btnLink"
            onClick={() => OpenModel(data)}
          >
            <i>
              <FaEdit />
            </i>
          </Button>{" "}
          {/* <Button
            size="sm"
            className="btn btn-danger btnLink"
            onClick={() => alert(data.data.UserID)}
          >
            <i>
              <FaTrashAlt />
            </i>
          </Button> */}
        </div>
      ),
    },
    {
      headerName: "Segment",
      field: "Segment",
      filter: true,
      minWidth:200
    },
    {
      headerName: "Email",
      field: "EmailId",
      filter: true,
    },
    {
      headerName: "CC EmailId",
      field: "ccEmailId",
      filter: true,
      minWidth:500
    },
    {
      headerName: "Active",
      field: "Active",
      maxWidth:80
    },
    
  ];

  function resetState() {
    const newItems: any = {
      //UserID: 0,
      ID:0,
      FirstName: "",    
      EmailId: "",
      ccEmailId: "",
      Segment:"",
      Active: true
    };
    setEditEmailGroupData(newItems);
  }

  const OpenModel = (obj: any) => {
    
    if (typeof obj === "undefined") {
      resetState();
      handleShow();
      setIsUpdate(false);
      setTitle("Add Email Setting");
    } else {
      
      setEditEmailGroupData(obj.data);
      setIsUpdate(true);
      handleShow();
      setTitle("Update Email Setting");
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true
    };
  }, []);

  const Columns = [
    "Email",
    "ccEmailId",
    "FirstName",
    "Active",
    "EmailGroup",
    "Suppress"
    
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title="Email Settings" />
        <Toast message={message} toastKey={toastKey} />
        <div className="container-fluid">
          {EmailGroupRowData && (
            <CAgGrid
              Columns={Columns}
              rowData={EmailGroupRowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              OpenModel={OpenModel}
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="EmailSettings"
            />
          )}
        </div>
      </div>
      {show && (
        <EmailSettingModal
          show={show}
          handleClose={handleClose}
          editData={editEmailGroupData}
          setEmailSettingData={setEditEmailGroupData}
          handleClick={handleClick}
          setMessage={setMessage}
          isUpdate={isUpdate}
          handleRefresh={handleRefresh}
          title={title}
        />
      )}
    </>
  );
};

export default EmailSetting;
