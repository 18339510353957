import React, { useContext } from "react";
import "./App.css";
import "./Common.css";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./commponents/pages/Dashboard";
import Login from "./commponents/pages/Login";
import { Routes, Route, Outlet, useNavigate, Navigate } from "react-router-dom";

import NavigationBar from "./commponents/common/Navigationbar";
import RoleDetails from "./commponents/pages/Masters/RoleDetails";
import CompanyDetails from "./commponents/pages/Masters/CompanyDetails";
import UserDetails from "./commponents/pages/Masters/UserDetails";
import UserPrivileges from "./commponents/pages/Masters/UserPrivileges";
import OfferIndex from "./commponents/pages/Offers/OfferIndex";
import ContractIndex from "./commponents/pages/Contracts/ContractIndex";
import Contract from "./commponents/pages/Contracts/Contract";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "./commponents/pages/NotFound";
import Credentialing from "./commponents/pages/Offers/Credentialing";
import LogoutMessage from "./commponents/common/LogoutMessage";
import { clearStorage } from "./commponents/common/Util";
import AuthContext from "./commponents/common/AuthProvider";
import { GDSContextType } from "./commponents/common/@types.usr";
import { ToastContainer } from "react-toastify";
import CredentialingStarted from "./commponents/pages/Offers/CredentialingStarted";
import CredentialingCompleted from "./commponents/pages/Offers/CredentialingCompleted";
import ContractReport from "./commponents/pages/Reports/ContractReport";
import CredentialingReport from "./commponents/pages/Reports/CredentialingReport";
import OfferEntry from "./commponents/pages/Offers/OfferEntry";
import ContractRenewals from "./commponents/pages/Contracts/ContractRenewals";
import AcceptedOffers from "./commponents/pages/Offers/AcceptedOffers";
import OfferDetails from "./commponents/pages/Offers/OfferDetails";
import CredentialingPLI from "./commponents/pages/Offers/CredentialingPLI";
import TestDataUpload from "./commponents/pages/Masters/TestDataUpload";
import EmailSetting from "./commponents/pages/Masters/EmailSetting";
import CreateNewPassword from "./commponents/pages/Masters/CreateNewPassword";
import UserPermission from "./commponents/pages/Masters/UserPermission";
import AcceptedOffersAdmin from "./commponents/pages/Offers/AcceptedOffersAdmin";


function App() {
  const { auth, saveContext } = useContext(AuthContext) as GDSContextType;
  let navigate = useNavigate();
  const objdata = {
    Userdata: {
      CompanyName: "",
      EmailId: "",
      FirstName: "",
      IsAdmin: false,
      LastName: "",
      LoginId: "",
      MobileNo: "",
      PermissionID: 0,
      PermissionName: "",
      RoleID: 0,
      RoleName: "",
      UserId: 0,
    },
    UserPermission: {
      Key: 0,
      Value: "",
    },
  };
  function ForceLogout() {
   
    clearStorage();
    saveContext(objdata);
    let path = "/Login";
    navigate(path);
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/Login" replace />} />{" "}
      {/* This is the new line for the redirect */}
      <Route path="/Login" element={<Login />} />
      <Route
        element={
          <>
            <NavigationBar />
            <ToastContainer position="top-right" autoClose={4000} />
            <LogoutMessage ForceLogout={ForceLogout} />
            <Outlet />
          </>
        }
      >
        <Route element={<ProtectedRoute />}>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/UserDetails" element={<UserDetails />} />
          <Route path="/UserPermissions/:paramUserID/:paramCompanyID/:paramRoleType" element={<UserPermission />} />
          <Route path="/RoleDetails" element={<RoleDetails />} />
          <Route path="/CompanyDetails" element={<CompanyDetails />} />
          <Route path="/GetTestData" element={<TestDataUpload />} />
          <Route path="/GetOffers" element={<OfferIndex />} />
          <Route path="/GetOffers/:paramOfferID" element={<OfferEntry />} />
          <Route path="/CreateOffer" element={<OfferEntry />} />
          <Route path="/GetContracts" element={<ContractIndex />} />
          <Route path="/GetNewContracts" element={<ContractIndex />} />
          <Route path="/GetInProgressContracts" element={<ContractIndex />} />
          <Route path="/AcceptedOffers" element={<AcceptedOffers />} />
          <Route path="/AcceptedOffersEdit" element={<AcceptedOffersAdmin />} />
          <Route path="/OnBoardOffers" element={<AcceptedOffers />} />
          <Route path="/EmailSetting" element={<EmailSetting />} />
          <Route path="/ResetPassword" element={<CreateNewPassword />} />
          <Route 
          path="/OfferDetails/:paramOfferID/:paramCompanyID/:paramEdit" 
          element={<OfferDetails />} />
                   <Route 
          path="/OfferDetails/:paramOfferID/:paramCompanyID" 
          element={<OfferDetails />} />
          <Route
            path="/GetContractForRenewals"
            element={<ContractRenewals />}
          />
          <Route
            path="/Contract/:paramOfferID/:paramContractID/:paramCompanyID"
            element={<Contract />}
          />
          <Route
            path="/Contract/:paramOfferID/:paramContractID/:paramCompanyID/:paramRenewal"
            element={<Contract />}
          />
          <Route path="/GetOfferToCreden" element={<Credentialing />} />
          <Route
            path="/GetOfferToCredenStarted"
            element={<CredentialingStarted />}
          />
          <Route
            path="/GetOfferToCredenComplete"
            element={<CredentialingCompleted />}
          />
           <Route
            path="/GetAddPLI"
            element={<CredentialingPLI />}
          />
          <Route path="/getAllUserPrivilege" element={<UserPrivileges />} />
          <Route path="/GetContractReport" element={<ContractReport />} />
          <Route path="/GetCredReport" element={<CredentialingReport />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
export default App;
