import { useCallback, useEffect, useMemo, useState } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi, getApiforFile } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import { FaDochub, FaFile, FaInfoCircle } from "react-icons/fa";
import { printDate } from "../../common/Util";
import Loading from "../../common/Loading";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import DocumentModal from "../Models/DocumentModal";
import { useNavigate } from "react-router-dom";
import { encryptString } from "../../common/cryptoUtils";

interface IContracts {
  OfferID: number;
  ContractID: number;
  CompanyID: number;
  ContractStatus: string;
  ForCause: string;
  ForConvenienceofPractice: string;
  ForConvenienceofOD: string;
  NonCompetition: string;
  NonSolicitationOfPractive: string;
  NonSolicitationofPatients: string;
  NonSolicitationofEmployees: string;
  DefSolicit: string;
  DefSolicit1: string;
  DefSolicit2: string;
  NonInterfernce: string;
  NonDisparagement: string;
  RepWarranties: string;
  RepWarranties1: string;
  RepWarranties2: string;
  RepWarranties3: string;
  RepWarranties4: string;
  RepWarranties5: string;
  RepWarranties6: string;
  RepWarranties7: string;
  RepWarranties8: string;
  CreatedDate: Date;
  CreatedBy: string;
  UpdatedDate: Date;
  UpdatedBy: string;
  FldOfferNavigation: {};
}

const ContractReport = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [editContractData, seteditContractData] = useState<IContracts[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [Docshow, setDocshow] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [SearchData, setSearchData] = useState({
    DateFrom: "",
    DateTo: "",
    Status: ""
  });
  
  const handleDocShow = useCallback(() => {
    setDocshow(true);
  }, []);
 
  const handleDocClose = useCallback(() => {
    setDocshow(false);
  }, []);
  const [contractData, setContractData] = useState<IContracts[]>([]);

  useEffect(() => {
    setLoading(true);
    async function populateStatus() {
      await getApi("/api/Common/GetLookupList?name=ContractStatus")
        .then((response) => {
          setStatusData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    async function populateOffers() {
      setLoading(true);
      await getApi("/api/Report/GetContractReport")
        .then((response) => {
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
          }
          setContractData(data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
      populateStatus();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  let navigate = useNavigate();
  const OpenOfferDetailView = (obj: any) => {   
    let paramOfferID = encryptString(obj?.data?.OfferID);
       let paramCompanyID = encryptString(obj?.data?.CompanyID);
    let path =
      "/OfferDetails/" +
      paramOfferID +
          "/" +
      paramCompanyID;
    navigate(path);
  };

  const handleSearchBtn = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    setLoading(true);
    await getApi("/api/Report/GetContractReport?StartDate=" + SearchData.DateFrom + "&EndDate=" + SearchData.DateTo + "&status=" + SearchData.Status)
      .then(async (response) => {
        let data = response.data;
        for (let index = 0; index < data.length; index++) {
          data[index].HireDate = printDate(data[index].HireDate);         
        }
        setContractData(data);
        
      })
      .catch(async (error) => {
        SwalAlert(error);
      })
      .finally(async () => {setLoading(false) });
  }
  const handleSearch = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    
    if (event.currentTarget.id === "DateFrom") {
      setSearchData({...SearchData,DateFrom:event.currentTarget.value});
    }
    if (event.currentTarget.id === "DateTo") {
      setSearchData({...SearchData,DateTo:event.currentTarget.value});
    }
    if (event.currentTarget.id === "Status") {
      setSearchData({...SearchData,Status:event.currentTarget.value});
 
    }
    
  }
  
  async function DownloadContract(obj: any) {
    const fileName =
    obj?.data?.EmployeeFirstName +
    "" +
    obj?.data?.EmployeeLastName +
    "Contract.docx";
    let contractId = obj?.data?.ContractID;
    await getApiforFile(
      "/api/Contracts/DownloadFinalContract?contractId=" + contractId
    )
      .then(async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(async (error) => {
        SwalAlert(error);
      })
      .finally(async () => {});

  }
  
  const Columns = [
    "EmployeeNumber",    
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    "EmployeeLocation",
    "LocationState",       
    "ContractStatus",
    "ConUserName"
  ];

  const columnDefs = [
   

    {
      headerName: "Employee #",
      field: "EmployeeNumber",        
      flex: 1,
      resizable: false,
      cellRenderer: (data: any) => (
        <div>
          <Button
            className="btnLink"
            onClick={() => DownloadContract(data)}
            variant="link"
          >
            <i>{data.data?.EmployeeNumber}</i>
          </Button>
        </div>
      ),
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "Address",
      field: "EmployeeLocation",
      filter: true,
      flex: 1,
      resizable: true,
    },
    {
      headerName: "State",
      field: "LocationState",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      field: "View Details",
      flex: 1,
      resizable: false,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            onClick={() => OpenOfferDetailView(data)}
          >
            <i
              title={
                "View Details for " +
                data.data.EmployeeFirstName +
                " " +
                data.data.EmployeeLastName
              }
            >
              <FaInfoCircle />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      field: "Document",
      flex: 1,
      resizable: false,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            onClick={() => OpenDocModel(data)}
          >
            <i>
              <FaFile />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "Status",
      field: "ContractStatus",
      filter: true,
      flex: 1,
      resizable: true,
    },
    {
      headerName: "Last User",
      field: "ConUserName",
      filter: true,
      flex: 1,
      resizable: true,
    },
  ];
 
  const OpenDocModel = (obj: any) => {
    if (typeof obj === "undefined") {
    } else {   
      seteditContractData(obj.data);
      handleDocShow();
      setTitle("Documents");
    }
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: false,
    };
  }, []);

  return (
    <>
      {" "}
      {loading && <Loading />}

      <div className="container-fluid">
        <CHeading Title="Contract Report" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 ">
              <Form.Group as={Row} className="mb-12 ExtraWidth" controlId="DateFrom">
                <Form.Label column sm={4}>
                  Offer Date from
                </Form.Label>
                <Col sm={6}>
                  <Form.Control type="Date" id="DateFrom" value={SearchData.DateFrom} onChange={handleSearch} />
                </Col>
              </Form.Group></div>
            <div className="col-md-3 ">
              <Form.Group as={Row} className="mb-12 ExtraWidth" controlId="DateTo">
                <Form.Label column sm={4}>
                  Offer Date to
                </Form.Label>
                <Col sm={6}>
                  <Form.Control type="Date" id="DateTo" value={SearchData.DateTo}   onChange={handleSearch} />
                </Col>
              </Form.Group></div>
            <div className="col-md-3 ">
              <Form.Group as={Row} className="mb-12 ExtraWidth" controlId="Status">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col sm={6}>
                  <Form.Select aria-label="Select status"
                    onChange={handleSearch}
                    id="Status"
                    value={SearchData.Status}>
                    <option >Select</option>
                    {statusData.map((data: any) => (
                      <option key={data.Value} value={data.Value}>
                        {data.Text}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group></div>
            <div className="col-md-3">
              <Button type="submit" className="mb-2 btn btn-sm" onClick={handleSearchBtn}>Search</Button>
            </div>
          </div>


          {contractData && (
            <CAgGrid
              Columns={Columns}
              rowData={contractData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}              
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="ContractsReport"
              Gridheight={"500px"}
            />
          )}
        </div>
      </div>
      
      {Docshow && (
        <DocumentModal
          show={Docshow}
          handleClose={handleDocClose}
          editData={editContractData}          
          title={title}
          isModelDisable={true}
          isButtonHideFromModel={true}
        />
      )}
    </>
  );
};

export default ContractReport;
