import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import swalAlert from "../../common/SwalAlert";
import OfferModel from "../Models/OfferModel";
import Loading from "../../common/Loading";
import Toast from "../../common/Toast";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  printDate,
} from "../../common/Util";

interface IOffers {
  OfferDate: Date;
  CompanyName: string;
  LocationState: string;
  OfferID: number;
  CompanyID: number;
  ContractID: number;
  EmployeeNumber: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  EmployeeLocation: string;
  BaseSalary: number;
  DailyRate: number;
  ODContract_ProductionPercentage: number;
  ODContract_EnrolledInHealthBenefits: string;
  ODContract_PTODays: number;
  ODContract_PaidCEDays: number;
  ODContract_LegalEntity: string;
  ODContract_AgreementType: string;
  OriginalHireDate: Date;
  HireDate: Date;
  ODContract_RenewalDate: Date;
  ODContract_AutoRenew: boolean;
  ODContract_ContractSchedule: string;
  ODContract_ContractScheduleFullDescription: string;
  ODContract_ProductionThreshold: number;
  ODContract_ProductionPaymentFrequency: string;
  ODContract_AOADuesPercentage: number;
  ODContract_StateDuesPercentage: number;
  ODContract_LicensureDues: number;
  ODContract_MultipleLocations: string;
  ODContract_SecondaryLocation: string;
  Region: string;
  EmployeeGender: string;
  EmployeeBirthDate: Date;
  SeniorityDate: Date;
  TerminationDate: Date;
  Job: string;
  PayType: string;
  PayClass: string;
  ODContract_TuitionReimbursement: number;
  ODContract_CarTravelAllowanceMonthly: number;
  ODContract_SignOnBonus: number;
  ODContract_CEReimbursement: number;
  ODContract_PaidHolidays: number;
  ODContract_PLIProvision: boolean;
}

const CredentialingReport = () => {
  const [loading, setLoading] = useState(false);
  const [credentialingData, setCredentialingData] = useState<IOffers[]>([]);  
  const [statusData, setStatusData] = useState([]);
  const [SearchData, setSearchData] = useState({
    DateFrom: "",
    DateTo: "",
    Status: ""
  });
  const [refresh, setrefresh] = useState(0);
  const [message, setMessage] = useState("");
  const [toastKey, setToastKey] = useState<number>(0);

 
  useEffect(() => {

    async function populateStatus() {
      await getApi("/api/Common/GetLookupList?name=CredentialingStatus")
        .then((response) => {
          setStatusData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          swalAlert(error);
        });
    }
    async function populateOffers() {
      setLoading(true);
      await getApi("/api/Report/GetCredReport")
        .then((response) => {
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
        data[index].CredStartDate = printDate(data[index].CredStartDate);
        data[index].CompletionDate = printDate(data[index].CompletionDate);
        data[index].FullCredStartDate = printDate(data[index].FullCredStartDate);
        data[index].CredCompletionDate = printDate(data[index].CredCompletionDate);
          }
          setCredentialingData(data);
          setLoading(false);
        })
        .catch((error) => {
          swalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
      populateStatus();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  
  const handleSearchBtn = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    setLoading(true);
    await getApi("/api/Report/GetCredReport?StartDate=" + SearchData.DateFrom + "&EndDate=" + SearchData.DateTo + "&status=" + SearchData.Status)
    .then((response) => {
      let data = response.data;
      for (let index = 0; index < data.length; index++) {
        data[index].HireDate = printDate(data[index].HireDate);
        data[index].CredStartDate = printDate(data[index].CredStartDate);
        data[index].CompletionDate = printDate(data[index].CompletionDate);
        data[index].FullCredStartDate = printDate(data[index].FullCredStartDate);
        data[index].CredCompletionDate = printDate(data[index].CredCompletionDate);
      }
      setCredentialingData(data);
      setLoading(false);
    });
  }
  const handleSearch = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    
    if (event.currentTarget.id == "DateFrom") {
      setSearchData({...SearchData,DateFrom:event.currentTarget.value});
    }
    if (event.currentTarget.id == "DateTo") {
      setSearchData({...SearchData,DateTo:event.currentTarget.value});
    }
    if (event.currentTarget.id == "Status") {
      setSearchData({...SearchData,Status:event.currentTarget.value});
    }
    
  }
  const columnDefs = [
    {
      headerName: "Employee #",
      field: "EmployeeNumber",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      maxWidth: 120,
    },
    // {
    //   headerName: "Address",
    //   field: "EmployeeLocation",
    //   flex:1
    // },
    // {
    //   headerName: "State",
    //   field: "LocationState",
    //   filter: true,
    //   maxWidth: 90,
    // },   
    {
      headerName: "Credentialing Status",
      field: "CredStatus",
      filter: true,
      maxWidth: 200,
    },
    {
      headerName: "PSV Start",
      field: "CredStartDate",
      filter: true,
      flex:1
    },
    {
      headerName: "PSV Complete",
      field: "CredCompletionDate",
      filter: true,
      flex:1
    },
    {
      headerName: "Full Cred Start",
      field: "FullCredStartDate",
      filter: true,
      flex:1
    },
    {
      headerName: "Full Cred End",
      field: "CompletionDate",
      filter: true,
      flex:1
    },
    {
      headerName: "Last User",
      field: "CredUserName",
      filter: true,
      flex:1
    }
  ];


  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const Columns = [
    "EmployeeNumber",    
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    // "EmployeeLocation",
    // "LocationState",
    "CredStatus",
    "CredStartDate",
    "CredCompletionDate",
    "FullCredStartDate",
    "CompletionDate",
    "CredUserName"

  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
      <Toast message={message} toastKey={toastKey} />
        <CHeading Title="Credentialing Report" />
        <div className="container-fluid">
        <div className="row">
            <div className="col-md-3 ">
              <Form.Group as={Row} className="mb-12 ExtraWidth" controlId="DateFrom">
                <Form.Label column sm={4}>
                  Offer Date from
                </Form.Label>
                <Col sm={6}>
                  <Form.Control type="Date" id="DateFrom" value={SearchData.DateFrom} onChange={handleSearch} />
                </Col>
              </Form.Group></div>
            <div className="col-md-3 ">
              <Form.Group as={Row} className="mb-12 ExtraWidth" controlId="DateTo">
                <Form.Label column sm={4}>
                  Offer Date to
                </Form.Label>
                <Col sm={6}>
                  <Form.Control type="Date" id="DateTo" value={SearchData.DateTo}   onChange={handleSearch} />
                </Col>
              </Form.Group></div>
            <div className="col-md-3 ">
              <Form.Group as={Row} className="mb-12 ExtraWidth" controlId="Status">
                <Form.Label column sm={4}>
                  Status
                </Form.Label>
                <Col sm={6}>
                  <Form.Select aria-label="Select status"
                    onChange={handleSearch}
                    id="Status"
                    value={SearchData.Status}>
                    <option >Select</option>
                    {statusData.map((data: any) => (
                      <option key={data.Value} value={data.Value}>
                        {data.Text}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group></div>
            <div className="col-md-3">
              <Button type="submit" className="mb-2 btn btn-sm" onClick={handleSearchBtn}>Search</Button>
            </div>
          </div>
          {credentialingData && (
            <CAgGrid
              Columns={Columns}
              rowData={credentialingData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="Credentialing"
              Gridheight={"500px"}
            />
          )}
        </div>
      </div>
      
    </>
  );
};

export default CredentialingReport;
