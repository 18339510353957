import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CFooter from "../common/CFooter";
import { FaUser, FaKey } from "react-icons/fa";
import { getApi, postApi } from "../../AxiosRequest";
import SwalAlert from "../common/SwalAlert";
import {  Form } from "react-bootstrap";
import { setDatatoStorage } from "../common/Util";
import Swal from "sweetalert2";
import AuthContext from "../common/AuthProvider";
import { GDSContextType } from "../common/@types.usr";
import Loading from "../common/Loading";
import companyLogo from "../common/Image/keplrLogo.png";
import ForgetPasswordModel from "./Models/ForgetPasswordModel";

function Login() {
  const { saveContext } = useContext(AuthContext) as GDSContextType;
  const [loading, setloading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loginUser, setLoginUser] = useState({
    Username: "",
    Password: "",
  });
  const [show, setShow] = useState(false);
  const handleShow = useCallback((e:any) => {
    e.preventDefault();
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  useEffect(() => {
    document.body.classList.add("body-green");
    return () => {
      document.body.classList.remove("body-green");
    };
  }, []);

  let navigate = useNavigate();
  let path = "/Dashboard";
  const GetUserDetails = async () => {
    await getApi("/api/Auth/getLoginUserDetails")
      .then(async (response) => {
        let data = await response.data;

        setDatatoStorage("Userdata", data.Userdata);
        setDatatoStorage("UserPermission", data.UserPermission);
        saveContext(response.data);
      })
      .catch(async (error) => {
        SwalAlert(error);
      })
      .finally(async () => {});
  };

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();

    if (loginUser.Username.length === 0) {
      Swal.fire({
        timer: 2500,
        icon: "error",
        title: "Oops...",
        text: "Username Required!",
      });
      return;
    }
    if (loginUser.Password.length === 0) {
      Swal.fire({
        timer: 2500,
        icon: "error",
        title: "Oops...",
        text: "Password Required!",
      });
      return;
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setloading(true);
      await postApi("/api/Auth/Authenticate", loginUser)
        .then(async (response) => {
       
          let data = await response.data;
          if (data.AccessToken != null) {
            setloading(false);
            setDatatoStorage("AccessToken", data.AccessToken);
            setDatatoStorage("RefreshToken", data.RefreshToken);
            await GetUserDetails();
            navigate(path);
          }
        })
        .catch(async (error) => {
         let mssg="Login failed. Please check your credentials and try again. If you've forgotten your password, use the 'Forgot Password'";
        
            Swal.fire({
              //timer: 2500,
              //icon: "error",
              title: "Oops...",
              text: error.response.data=='Incorrect Password'?mssg:error.response.data,
            });         
        
          setloading(false);
        })
        .finally(async () => {
        });
    }
    setValidated(true);
  };

  return (
    <>
      {loading && <Loading />}
      <div className="mt-3">
        <div className="wrapper">
          {/* <div className="logo">
            <img src={loginImag} alt="" />
          </div> */}
          {/* <div className="text-center mt-4 name">Keplr Vision</div> */}
          <div
            className="Companylogo logo-container"
            style={{ marginBottom: "3rem" }}
          >
            <img src={companyLogo} alt="" />
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="form-field d-flex align-items-center">
              <span>
                <FaUser />
              </span>
              <input
                type="text"
                name="userName"
                id="userName"
                placeholder="Username"
                value={loginUser.Username}
                autoComplete="off"
                onChange={(e) =>
                  setLoginUser({ ...loginUser, Username: e.target.value })
                }
                required={true}
              />
            </div>
            <div className="form-field d-flex align-items-center">
              <span>
                <FaKey />
              </span>
              <input
                type="password"
                name="password"
                id="pwd"
                placeholder="Password"
                value={loginUser.Password}
                autoComplete="off"
                onChange={(e) =>
                  setLoginUser({ ...loginUser, Password: e.target.value })
                }
                required={true}
              />
            </div>
            <button className="btn mt-3" type="submit">
              Login
            </button>
          </Form>
          <div className="fs-6 mt-3" style={{float:'right',fontWeight:'700'}}>
          {/* <Button variant="link" onClick={handleShow}>Forgot password?</Button> */}
            <a href="" onClick={(e)=>handleShow(e)}>Forgot password?</a> 
            {/* or{" "}
            <a href="Signup">Sign up</a> */}
          </div>
        </div>
        <CFooter />
        
      </div>
      {show && (
        <ForgetPasswordModel
          show={show}
          handleClose={handleClose}
          // editData={editCompanyData}
          // editData={editData}         
          //handleRefresh={handleRefresh}
          title={"Forgot Password"}
        />
      )}
    </>
  );
}

export default Login;
