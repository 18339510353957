import axios from "axios";

import {
  forceLogout,
  getAccessToken,
  getRefreshToken,
  getUserIdFromLocalStorage,
  setDatatoStorage,
} from "./commponents/common/Util";
export const AxiosRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});
AxiosRequest.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (error) => Promise.reject(error)
);
AxiosRequest.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      const refreshToken = getRefreshToken();
      const User = getUserIdFromLocalStorage();
      if (refreshToken) {
        try {
          let param = {
            userId: User,
            refreshToken: refreshToken,
          };
          const response = await postApi("/api/Auth/refreshToken", null, param);
          let data = await response.data;

          const newAccessToken = data;
          setDatatoStorage("AccessToken", newAccessToken);
          originalRequest.headers.Authorization = newAccessToken
            ? `Bearer ${newAccessToken}`
            : "";
          return AxiosRequest(originalRequest);
        } catch (refreshError: any) {
          if (refreshError?.response?.status === 419) {
            originalRequest.retry = false;
            forceLogout();
            window.location.href = "/Login";
          }
        }
      }
    }
    return Promise.reject(error);
  }
);

export const getApi = async (url: string) => {
  try {
    return await AxiosRequest.get(url);
  } catch (error: any) {
    throw error;
  }
};

export const getApiforFile = async (url: string) => {
  try {
    return await AxiosRequest.get(url, {
      responseType: "blob",
    });
  } catch (error: any) {
    throw error;
  }
};

export const postApi = async (
  url: string,
  data: any | null | undefined = null,
  params: any | null | undefined = null,
  isFile: boolean | null | undefined = false
) => {
  try {
    if (isFile === true) {
      return await AxiosRequest.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params,
      });
    } else {
      if (
        data !== null &&
        data !== undefined &&
        data !== "" &&
        params === null
      ) {
        return await AxiosRequest.post(url, data);
      } else if (
        data !== null &&
        data !== undefined &&
        data !== "" &&
        params !== null
      ) {
        return await AxiosRequest.post(url, data, {
          headers: { "Content-Type": "application/json" },
          params,
        });
      } else if (data === null && params !== null) {
        return await AxiosRequest.post(url, null, {
          headers: { "Content-Type": "application/json" },
          params,
        });
      } else {
        return await AxiosRequest.post(url, null);
      }
    }
  } catch (error) {
    throw error;
  }
};

export const putApi = async (
  url: string,
  data: any | null | undefined = null,
  params: any | null | undefined = null
) => {
  try {
    if (data !== null && data !== undefined && data !== "" && params === null) {
      return await AxiosRequest.put(url, data);
    } else if (
      data !== null &&
      data !== undefined &&
      data !== "" &&
      params !== null
    ) {
      return await AxiosRequest.put(url, data, {
        headers: { "Content-Type": "application/json" },
        params,
      });
    } else if (data === null && params !== null) {
      return await AxiosRequest.put(url, null, {
        headers: { "Content-Type": "application/json" },
        params,
      });
    } else {
      return await AxiosRequest.put(url, null);
    }
  } catch (error) {
    throw error;
  }
};
