import { useEffect, useState,useContext } from "react";
import { CHeading } from "../CHeading";
import { getApi } from "../../AxiosRequest";
import SwalAlert from "../common/SwalAlert";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import PieChart from "./Charts/PieChart";
import AuthContext from "../common/AuthProvider";
import { GDSContextType } from "../common/@types.usr";
import ChangePasswordModel from "./Models/ChangePasswordModel";

Chart.register(CategoryScale);
interface ChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
    borderColor: string[]; 
    borderWidth: number[];
  }[];
}

const Dashboard = (props:any) => {
  console.log("🚀 ~ file: Dashboard.tsx:24 ~ Dashboard ~ props:", props)
  const { auth, saveContext } = useContext(AuthContext) as GDSContextType;

  // const [isOffer] = useState(
  //   auth?.UserPermission.length > 0
  //     ? auth?.UserPermission?.some((obj: any) => obj.Key === 1)
  //       ? true
  //       : false
  //     : false
  // );

  const [isFirstTime] = useState(
    auth?.Userdata?.FirstCheck===true
        ? true
        : false
     
  );
  console.log(auth?.Userdata.FirstCheck,isFirstTime,'authval');
  const [isContract] = useState(
    auth?.UserPermission.length > 0
      ? auth?.UserPermission?.some((obj: any) => obj.Key === 2)
        ? true
        : false
      : false
  );
  const [isAdmin] = useState(
    auth?.UserPermission.length > 0
      ? auth?.UserPermission?.some((obj: any) => obj.Key === 3)
        ? true
        : false
      : false
  );
  const [isCredentialing] = useState(
    auth?.UserPermission.length > 0
      ? auth?.UserPermission?.some((obj: any) => obj.Key === 4)
        ? true
        : false
      : false
  );
  

  const [chartDataForContract, setChartDataForContract] = useState<ChartData>({
    labels: [],
    datasets: [],
  });
  const [chartDataForOffers, setChartDataForOffers] = useState<ChartData>({
    labels: [],
    datasets: [],
  });
  const [chartDataForCred, setChartDataForCred] = useState<ChartData>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    async function populateChart() {
      await getApi("/api/common/GetContractChartData")
        .then((response) => {
          
          let apiData = response.data.filter(
            (item: any) => item.type === "Contract"
          );
          let labels = apiData.map((item: any) => item.name);
          let data = apiData.map((item: any) => item.text);
          setChartDataForContract({
            labels: labels,
            datasets: [
              {
                data: data,
                backgroundColor: [
                  "rgb(255,213,0)",
                  "#3498DB",
                  "rgba(157, 107, 212, 0.88)",
                  "rgb(139, 202, 108)",
                  "rgba(247, 55, 55, 0.88)",
                ],
                borderColor: [
                  "lightGray",
                  "lightGray",
                  "lightGray",
                  "lightGray",
                  "lightGray",
                ],
                borderWidth: [3, 3, 3, 3, 3],
              },
            ],
          });

          apiData = response.data.filter((item: any) => item.type === "Offer");
          labels = apiData.map((item: any) => item.name);
          data = apiData.map((item: any) => item.text);
          setChartDataForOffers({
            labels: labels,
            datasets: [
              {
                data: data,
                backgroundColor: [
                  "rgb(255,213,0)",
                  "#3498DB",
                  "rgba(157, 107, 212, 0.88)",
                  "rgb(139, 202, 108)",
                  "rgba(247, 55, 55, 0.88)",
                  "rgba(180, 55, 55, 0.88)",
                ],
                borderColor: [
                  "lightGray",
                  "lightGray",
                  "lightGray",
                  "lightGray",
                  "lightGray",
                ],
                borderWidth: [3, 3, 3, 3, 3],
              },
            ],
          });

          apiData = response.data.filter(
            (item: any) => item.type === "Credentialing"
          );
          labels = apiData.map((item: any) => item.name);
          data = apiData.map((item: any) => item.text);
          setChartDataForCred({
             
            labels: labels,
            datasets: [
              {
                data: data,
                backgroundColor: [
                  "rgb(255,213,0)",
                  "#3498DB",
                  "rgba(157, 107, 212, 0.88)",
                  "rgb(139, 202, 108)",
                  "rgba(247, 55, 55, 0.88)",
                  "rgba(180, 55, 55, 0.88)",
                ],
                borderColor: [
                  "lightGray",
                  "lightGray",
                  "lightGray",
                  "lightGray",
                  "lightGray",
                ],
                borderWidth: [3, 3, 3, 3, 3],
              },
            ],
          });
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {

      
      populateChart();
    }
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <>
    <div className="Container-fluid" hidden={false}>
      <CHeading Title="Dashboard" />
      <div className="col-md-12 row">
        <div className="col-md-3"  style={{ display: (isContract||isAdmin) ? "flex" : "none" }}>
          <PieChart
            Title="Contract"
            data={{
              labels: chartDataForContract.labels,
              datasets: chartDataForContract.datasets.map((dataset: any) => ({
                ...dataset,
                data: dataset.data,
              })),
            }}
          />
        </div>

        <div className="col-md-3"  style={{ display: (isContract||isAdmin) ? "flex" : "none" }}>
          <PieChart
            Title="Accepted Offers"
            data={{
              labels: chartDataForOffers.labels,
              datasets: chartDataForOffers.datasets.map((dataset: any) => ({
                ...dataset,
                data: dataset.data,
              })),
            }}
          />
        </div>

        {/* <div className="col-md-3"  style={{ display: (isCredentialing||isAdmin) ? "flex" : "none" }}> */}
         <div className="col-md-3"  style={{ display: (isAdmin) ? "flex" : "none" }}>
          <PieChart
            Title="Credentialing"
            data={{
              labels: chartDataForCred.labels,
              datasets: chartDataForCred.datasets.map((dataset: any) => ({
                ...dataset,
                data: dataset.data,
              })),
            }}
          />
        </div>
      </div>
    </div>
    {isFirstTime && (
      <ChangePasswordModel
        show={isFirstTime}
        //handleClose={handleClose}
         editData={auth?.Userdata.UserId}
        // editData={editData}         
        //handleRefresh={handleRefresh}
        title={"Change Password"}
      />
    )}
    </>
  );
};
export default Dashboard;
