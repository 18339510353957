import React from "react";
import { Button, Table } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { getApi, getApiforFile } from "../../AxiosRequest";
import Swal from "sweetalert2";

interface TableProps {
  rowData: (any | any[])[][];
  columnHeaders: string[];
  tableHeight?: string;
  tableWidth?: string;
  handleRefresh: () => void;
  isDeleteCol?:boolean,
  DeleteType?: string;
  isHiddenCol?:boolean
}

const TableComponent: React.FC<TableProps> = ({
  rowData,
  columnHeaders,
  tableHeight,
  tableWidth,
  handleRefresh,
  isDeleteCol,
  DeleteType,
  isHiddenCol
}) => {
  const DeleteOtherContractDocument = async (
    event: any,
    Id: any,
    FileName: any
  ) => {
    event.preventDefault();
    Swal.fire({
      timer: 8000,
      title: "Are you sure?",
      text: "you want to delete this file " + FileName,
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes, Delete!",
      showLoaderOnConfirm: true,
      allowEscapeKey: false,
      preConfirm: () => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (Id !== null) {
          await getApi("api/Contracts/DeleteOtherContractDocument?DocId=" + Id)
            .then(async (response: any) => {
              Swal.fire(response.data, "", "success");
              handleRefresh();
            })
            .catch((error) => {});
        }
      }
    });
  };

  const DeleteOfferDocument = async (
    event: any,
    Id: any,
    FileName: any
  ) => {
    event.preventDefault();
    Swal.fire({
      timer: 8000,
      title: "Are you sure?",
      text: "you want to delete this file " + FileName,
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Yes, Delete!",
      showLoaderOnConfirm: true,
      allowEscapeKey: false,
      preConfirm: () => {
        return new Promise<void>((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (Id !== null) {
          await getApi("api/Contracts/DeleteOfferDocument?DocId=" + Id)
            .then(async (response: any) => {
              Swal.fire(response.data, "", "success");
              handleRefresh();
            })
            .catch((error) => {});
        }
      }
    });
  };
  const downloadFile = async (event: any, file: any, fileName: any) => {
    event.preventDefault();
    if (file.length > 0) {
      await getApiforFile(
        "api/UploadFile/DownloadFile?FileName=" +
          fileName +
          "&&FilePath=" +
          file
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {});
    }
  };

  return (
    <div>
      {rowData.length > 0 && (
        <Table
          striped
          bordered
          hover
          responsive
          size="sm"
          style={{
            width: tableWidth,
            border: "1px solid darkblue",
          }}
        >
          <thead>
            <tr>
              {columnHeaders.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rowData.map((row: any, rowIndex: number) => (
              <tr key={rowIndex}>
                <td>{row.DocumentType}</td>
                <td>{row.CreatedBy}</td>
                <td>{row.CreatedDate}</td>
                <td>
                  <a
                    href="#"
                    onClick={(e) => downloadFile(e, row.FilePath, row.FileName)}
                  >
                    <i>{row.FileName}</i>
                  </a>
                </td>
                <td hidden={isHiddenCol}>
                  <Button
                  disabled={isDeleteCol}
                  
                    size="sm"
                    className="btn btn-danger "
                    onClick={(e) => {if(DeleteType==="Contract"){
                      DeleteOtherContractDocument(
                        e,
                        row.DocumentId,
                        row.FileName
                      )
                    }else if(DeleteType==="Offer"){
                      DeleteOfferDocument(
                        e,
                        row.DocumentId,
                        row.FileName
                      )
                    }
                    }
                  }
                  >
                    <i>
                      <FaTrashAlt />
                    </i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default TableComponent;
