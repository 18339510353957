import { useCallback, useEffect, useMemo, useState } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import OfferModel from "../Models/OfferModel";
import { Button } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { printDate } from "../../common/Util";
import Loading from "../../common/Loading";
import {  useNavigate } from "react-router-dom";
import { encryptString } from "../../common/cryptoUtils";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
interface IContracts {
  OfferID: number;
  ContractID: number;
  CompanyID: number;
  ContractStatus: string;
  ForCause: string;
  ForConvenienceofPractice: string;
  ForConvenienceofOD: string;
  NonCompetition: string;
  NonSolicitationOfPractive: string;
  NonSolicitationofPatients: string;
  NonSolicitationofEmployees: string;
  DefSolicit: string;
  DefSolicit1: string;
  DefSolicit2: string;
  NonInterfernce: string;
  NonDisparagement: string;
  RepWarranties: string;
  RepWarranties1: string;
  RepWarranties2: string;
  RepWarranties3: string;
  RepWarranties4: string;
  RepWarranties5: string;
  RepWarranties6: string;
  RepWarranties7: string;
  RepWarranties8: string;
  CreatedDate: Date;
  CreatedBy: string;
  UpdatedDate: Date;
  UpdatedBy: string;
  FldOfferNavigation: {};
}

const ContractRenewals = () => {
  const [title, setTitle] = useState("");
  const [PageTitle, setPageTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [editContractData, seteditContractData] = useState<IContracts[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [show, setShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [SearchData, setSearchData] = useState({
    DateFrom: "",
    DateTo: ""
  });
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const [contractData, setContractData] = useState<IContracts[]>([]);
  const urlElementsAction = window.location.href.split("/")[3];

  useEffect(() => {
    async function populateOffers() {
      setLoading(true);
      let url = "";
      switch (urlElementsAction) {
        case "GetContractForRenewals":
          url = "/api/Contracts/getAllContractForRenewals";
          setPageTitle("All Renewals");
          break;
      }

      await getApi(url)
        .then((response) => {
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
            data[index].ODContract_RenewalDate = printDate(
              data[index].ODContract_RenewalDate
            );
          }
          setContractData(data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh, urlElementsAction]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };
  let navigate = useNavigate();

  const OpenContractView = (obj: any) => {
    let paramOfferID = encryptString(obj?.data?.OfferID);
    let paramContractID = encryptString(obj?.data?.ContractID);
    let paramCompanyID = encryptString(obj?.data?.CompanyID);
    let paramRenewal = "true";
    let path =
      "/Contract/" +
      paramOfferID +
      "/" +
      paramContractID +
      "/" +
      paramCompanyID +
      "/" +
      paramRenewal;

    navigate(path);
  };

  const Columns = [
    "EmployeeNumber",
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    "EmployeeLocation",
    "ODContract_RenewalDate",
    "ContractStatus",
  ];

  const columnDefs = [
    {
      headerName: "Employee #",
      field: "EmployeeNumber",
      filter: true,
      flex: 1,
      
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      flex: 1,
      
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      flex: 1,
      
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      flex: 1,
      
    },
    {
      headerName: "Address",
      field: "EmployeeLocation",
      filter: true,
      flex: 1,
      
    },
    {
      headerName: "Renewal Date",
      field: "ODContract_RenewalDate",
      filter: true,
      flex: 1,
      
    },    
    {
      field: "Action",
      flex: 1,
      cellRenderer: (data: any) => (
        <div>
          <Button
            className="btnLink"
            onClick={() => OpenContractView(data)}
            variant="link"
          >
            <i>View/Edit</i>
          </Button>
        </div>
      ),
    },

    // {
    //   headerName: "Status",
    //   field: "ContractStatus",
    //   filter: true,
    //   flex: 1,
    //   resizable: true,
    // },
  ];

  const OpenModel = (obj: any) => {
    if (typeof obj === "undefined") {
    } else {
      seteditContractData(obj.data);
      setIsUpdate(true);
      handleShow();
      setTitle("View Offer Details");
    }
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);
  const handleSearchBtn = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    setLoading(true);
    await getApi("/api/Contracts/getAllContractForRenewals?StartDate=" + SearchData.DateFrom + "&EndDate=" + SearchData.DateTo)
      .then(async (response) => {
        let data = response.data;
        for (let index = 0; index < data.length; index++) {
          data[index].HireDate = printDate(data[index].HireDate);
            data[index].ODContract_RenewalDate = printDate(
              data[index].ODContract_RenewalDate
            );       
        }
        setContractData(data);
        
      })
      .catch(async (error) => {
        SwalAlert(error);
      })
      .finally(async () => {setLoading(false) });
  }
  const handleSearch = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    
    if (event.currentTarget.id === "DateFrom") {
      setSearchData({...SearchData,DateFrom:event.currentTarget.value});
    }
    if (event.currentTarget.id === "DateTo") {
      setSearchData({...SearchData,DateTo:event.currentTarget.value});
    }
    
    
  }
  return (
    <>
      {" "}
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title={PageTitle} />
        <div className="container-fluid">
        <div className="row">
            <div className="col-md-3 ">
              <Form.Group as={Row} className="mb-12 ExtraWidth" controlId="DateFrom">
                <Form.Label column sm={5}>
                  Renewal Date from
                </Form.Label>
                <Col sm={6}>
                  <Form.Control type="Date" id="DateFrom" value={SearchData.DateFrom} onChange={handleSearch} />
                </Col>
              </Form.Group></div>
            <div className="col-md-3 ">
              <Form.Group as={Row} className="mb-12 ExtraWidth" controlId="DateTo">
                <Form.Label column sm={5}>
                  Renewal Date to
                </Form.Label>
                <Col sm={6}>
                  <Form.Control type="Date" id="DateTo" value={SearchData.DateTo}   onChange={handleSearch} />
                </Col>
              </Form.Group></div>
         
            <div className="col-md-3">
              <Button type="submit" className="mb-2 btn btn-sm" onClick={handleSearchBtn}>Search</Button>
            </div>
          </div>
          {contractData && (
            <CAgGrid
              Columns={Columns}
              rowData={contractData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              OpenModel={OpenModel}
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="AllRenewals"
              Gridheight={"500px"}
            />
          )}
        </div>
      </div>
      {show && (
        <OfferModel
          show={show}
          handleClose={handleClose}
          editData={editContractData}
          seteditData={seteditContractData}
          isUpdate={isUpdate}
          handleRefresh={handleRefresh}
          title={title}
          isModelDisable={true}
          isButtonHideFromModel={true}
        />
      )}
    </>
  );
};

export default ContractRenewals;
