import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { postApi, putApi } from "../../../AxiosRequest";
import Swal from "sweetalert2";
import SwalAlert from "../../common/SwalAlert";

function CompanyModel(props: any) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        timer: 2500,
        title: "Do you want to save the changes?",
        icon:"info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Save",
        // denyButtonText: `Don't save`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postApi("/api/Company/createCompany", props.editData)
            .then(async (response) => {
              props.handleClose();
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
    setValidated(true);
  };

  const handleUpdate = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        timer: 2500,
        title: "Do you want to update the changes?",
        icon:"info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Update",
        // denyButtonText: `Don't Update`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await putApi("/api/Company/editCompany", props.editData)
            .then(async (response) => {
              props.handleClose();
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("Changes are not updated", "", "info");
        }
      });
    }
    setValidated(true);
  };

  return (
    <Modal
      size="lg"
      show={props.show}
      onHide={props.handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={props.isUpdate === true ? handleUpdate : handleSubmit}
        >
          <div className="container">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <input type="hidden" value={props.editData?.CompanyID} />
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.CompanyName}
                      placeholder="Company Name"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          CompanyName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Form.Label>Address 1</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.Address1}
                      placeholder="Address"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          Address1: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom03">
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.Address2}
                      placeholder="Address"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          Address2: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  {" "}
                  <Form.Group as={Col} md="12" controlId="validationCustom04">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.City}
                      placeholder="City"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          City: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom05">
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.State}
                      placeholder="State"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          State: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom06">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.Country}
                      placeholder="Country"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          Country: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group as={Col} md="12" controlId="validationCustom07">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.PhoneNo}
                      placeholder="Phone Number"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          PhoneNo: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom08">
                    <Form.Label>Email ID</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.EmailID}
                      placeholder="Email ID"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          EmailID: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group as={Col} md="12" controlId="validationCustom09">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData?.MobileNo}
                      placeholder="Mobile Number"
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          MobileNo: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="12">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      label="Active"
                      checked={props.editData?.Active}
                      onChange={(e) =>
                        props.seteditCompanyData({
                          ...props.editData,
                          Active: e.target.checked,
                        })
                      }
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 btn-Model-margin">
            <Button size="sm" type="submit">
              {props.isUpdate === true ? "Update" : "Saved"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CompanyModel;
