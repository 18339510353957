import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getApi, postApi, putApi } from "../../../AxiosRequest";
import Swal from "sweetalert2";
import SwalAlert from "../../common/SwalAlert";
interface ICompany {
  code: number;
  name: string;
}

function RoleModel(props: any) {
  const [validated, setValidated] = useState(false);
  const [companyData, setCompanyData] = useState<ICompany[]>([]);
  
  useEffect(() => {
    async function populateCompany() {
      await getApi("/api/Common/getallCompany")
        .then((response) => {
          setCompanyData(response.data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
   
    let ignore = false;
    if (!ignore) {
      populateCompany();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        timer: 2500,
        title: "Do you want to save the changes?",
        icon:"info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Save",
        //denyButtonText: `Don't save`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postApi("/api/Roles/createRole", props.editData)
            .then(async (response) => {
              props.handleClose();
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
    setValidated(true);
  };

  const handleUpdate = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        timer: 2500,
        title: "Do you want to update the changes?",
        icon:"info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Update",
        // denyButtonText: `Don't Update`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await putApi("/api/Roles/editRole", props.editData)
            .then(async (response) => {
              props.handleClose();
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("Changes are not updated", "", "info");
        }
      });
    }
    setValidated(true);
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={props.isUpdate === true ? handleUpdate : handleSubmit}
        >
          <div className="container">
            <input type="hidden" value={props.editData.RoleID} />
            <Form.Group as={Col} md="10" controlId="validationCustom01">
              <Form.Label>Role name</Form.Label>
              <Form.Control
                required
                type="text"
                value={props.editData.RoleName}
                placeholder="Role Name"
                onChange={(e) =>
                  props.seteditRoleData({
                    ...props.editData,
                    RoleName: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group as={Col} md="10" controlId="validationCustom02">
              <Form.Label>Company</Form.Label>
              <Form.Select
                required
                aria-label="Select Company"
                onChange={(e) =>
                  props.seteditRoleData({
                    ...props.editData,
                    CompanyID: Number(e.target.value),
                  })
                }
                value={props.editData.CompanyID}
              >
                <option >Select</option>
                {companyData.map((data: any) => (
                  <option key={data.code} value={data.code}>
                    {data.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            
          </div>

          <div className="mt-2 btn-Model-margin">
            <Button size="sm" type="submit">
              {props.isUpdate === true ? "Update" : "Saved"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default RoleModel;
