import React, { ReactNode } from "react";
import { Modal, Button } from "react-bootstrap";

interface MyModalProps {
  showModal: boolean;
  closeModal: () => void;
  children: ReactNode;
}

const ContractRenewalModel: React.FC<MyModalProps> = ({ showModal, closeModal, children }) => {
  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={closeModal}
      dialogClassName="modal-lg"
    >
      <Modal.Body>{children}</Modal.Body>
      {/* <Modal.Footer></Modal.Footer> */}
    </Modal>
  );
};
export default ContractRenewalModel;
