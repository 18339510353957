import { useContext, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CFooter from "./CFooter";
import AuthContext from "./AuthProvider";
import { FaPowerOff } from "react-icons/fa";
import { clearStorage } from "./Util";
import { GDSContextType } from "./@types.usr";
import Toast from "./Toast";

function Navigationbar() {
  const { auth, saveContext } = useContext(AuthContext) as GDSContextType;
  const [message, setMessage] = useState("");
  const [toastKey, setToastKey] = useState<number>(0);
  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };
  const location = useLocation();
  const btnRef = useRef(null);

  const [isOffer] = useState(
    auth?.UserPermission.length > 0
      ? IsAccessible(1)
        ? true
        : false
      : false
  );
  const [isContract] = useState(
    auth?.UserPermission.length > 0
      ? IsAccessible(2)
        ? true
        : false
      : false
  );
  const [comp] = useState(process.env.REACT_APP_COMPANY);
  const [isAdmin] = useState(
    auth?.UserPermission.length > 0
      ? IsAccessible(3)
        ? true
        : false
      : false
  );

  const [isCredentialing] = useState(
    auth?.UserPermission.length > 0
      ? IsAccessible(4)
        ? true
        : false
      : false
  );
  const [isReports] = useState(
    auth?.UserPermission.length > 0
      ? IsAccessible(5)
        ? true
        : false
      : false
  );

  let navigate = useNavigate();

  const objdata = {
    Userdata: {
      CompanyName: "",
      EmailId: "",
      FirstName: "",
      IsAdmin: false,
      LastName: "",
      LoginId: "",
      MobileNo: "",
      PermissionID: 0,
      PermissionName: "",
      RoleID: 0,
      RoleName: "",
      UserId: 0,
    },
    UserPermission: {
      Key: 0,
      Value: "",
    },
  };

  function ForceLogout() {
    clearStorage();
    let path = "/Login";
    navigate(path);
  }

  function Logout() {
    setMessage("logout successfully.");
    handleClick();
    saveContext(objdata);
    ForceLogout();
  }
  function IsAccessible(valKey: any) {
    return auth?.UserPermission?.some((obj: any) => obj.Key === valKey)
  }
  const goToDashboard = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/Dashboard";
    navigate(path);
  };
  const gotoUserMaster = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/UserDetails";
    navigate(path);
  };
  const gotoRoleMaster = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/RoleDetails";
    navigate(path);
  };
  const gotoTestData = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetTestData";
    navigate(path);
  };
  const gotoEmailSetting = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/EmailSetting";
    navigate(path);
  };
  const gotoCreateNewPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/ResetPassword";
    navigate(path);
  };
  const gotoNewOffer = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/AcceptedOffersEdit";
    navigate(path);
  };

  const gotoCompanyMaster = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/CompanyDetails";
    navigate(path);
  };
  const gotoOffers = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetOffers";
    navigate(path);
  };
  const gotoAcceptedOffers = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/AcceptedOffers";
    navigate(path);
  };
  const gotoOnBoardOffers = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/OnBoardOffers";
    navigate(path);
  };
  const gotoContracts = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetContracts";
    navigate(path);
  };
  const gotoNewContracts = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetNewContracts";
    navigate(path);
  };
  const gotoInProgressContracts = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetInProgressContracts";
    navigate(path);
  };
  const gotoInOfferRenewal = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetContractForRenewals";
    navigate(path);
  };
  const gotoCredentialing = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    let path = "/GetOfferToCreden";
    navigate(path);
  };
  const gotoCredentialingStarted = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    let path = "/GetOfferToCredenStarted";
    navigate(path);
  };
  const gotoCredentialingCompleted = (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();

    let path = "/GetOfferToCredenComplete";
    navigate(path);
  };
  const gotoCredentialingPLI = (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();

    let path = "/GetAddPLI";
    navigate(path);
  };
  const gotoUserPrivileges = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetAllUserPrivilege";
    navigate(path);
  };
  //Reports
  const gotoContractReport = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetContractReport";
    navigate(path);
  };
  const gotoCredReport = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    let path = "/GetCredReport";
    navigate(path);
  };
  return (
    <>
      <Toast message={message} toastKey={toastKey} />
      <Navbar bg="dark" data-bs-theme="dark" expand="lg">
        <Container fluid>
          <Navbar.Brand href="#" onClick={goToDashboard}>

            {comp}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <NavDropdown
                style={{ display: isOffer ? "flex" : "none" }}
                title="Offers"
                id="navbarScrollingDropdown"
                className={
                  location.pathname === "/AcceptedOffers"||
                  location.pathname === "/OnBoardOffers"
                    ? "active"
                    : ""
                }
              >
                {IsAccessible(12) ?
                  <NavDropdown.Item href="#" onClick={gotoAcceptedOffers}>
                    Accepted Offers
                  </NavDropdown.Item> : ""}
                  {IsAccessible(12) ?
                  <NavDropdown.Item href="#" onClick={gotoOnBoardOffers}>
                    Onboarding Docs
                  </NavDropdown.Item> : ""}
              </NavDropdown>

              <NavDropdown
                style={{ display: isCredentialing ? "flex" : "none" }}
                title="Credentialing"
                id="navbarScrollingDropdown"
                className={
                  location.pathname === "/GetOfferToCreden" ||
                    location.pathname === "/GetOfferToCredenStarted" ||
                    location.pathname === "/GetOfferToCredenComplete" ||
                    location.pathname === "/GetAddPLI"
                    ? "active"
                    : ""
                }
              >
                {IsAccessible(13) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetOfferToCreden" ? "active" : ""
                    }
                    onClick={gotoCredentialing}
                  >
                    Start PSV
                  </NavDropdown.Item> : ""}

                {IsAccessible(14) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetOfferToCredenStarted"
                        ? "active"
                        : ""
                    }
                    onClick={gotoCredentialingStarted}
                  >
                    Complete PSV
                  </NavDropdown.Item>
                  : ""}
                {IsAccessible(24) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetAddPLI"
                        ? "active"
                        : ""
                    }
                    onClick={gotoCredentialingPLI}
                  >
                    Add PLI
                  </NavDropdown.Item> : ""}
                {IsAccessible(15) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetOfferToCredenComplete"
                        ? "active"
                        : ""
                    }
                    onClick={gotoCredentialingCompleted}
                  >
                    Complete Credentialing
                  </NavDropdown.Item>
                  : ""}
              </NavDropdown>



              <NavDropdown
                style={{ display: isContract ? "flex" : "none" }}
                title="Contracts"
                id="navbarScrollingDropdown"
                className={
                  location.pathname === "/GetNewContracts" ||
                    location.pathname === "/GetInProgressContracts" ||
                    location.pathname === "/GetContracts" ||
                    location.pathname === "/GetContractForRenewals" ||
                    location.pathname.includes("/Contract/")
                    ? "active"
                    : ""
                }
              >
                {IsAccessible(16) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetNewContracts" ? "active" : ""
                    }
                    onClick={gotoNewContracts}
                  >
                    New Contracts
                  </NavDropdown.Item> : ""}
                {IsAccessible(17) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetInProgressContracts"
                        ? "active"
                        : ""
                    }
                    onClick={gotoInProgressContracts}
                  >
                    In Progress Contracts
                  </NavDropdown.Item> : ""}
                {IsAccessible(18) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetContracts" ? "active" : ""
                    }
                    onClick={gotoContracts}
                  >
                    All Contracts
                  </NavDropdown.Item> : ""}
                {IsAccessible(19) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetContractForRenewals"
                        ? "active"
                        : ""
                    }
                    onClick={gotoInOfferRenewal}
                  >
                    All Renewals
                  </NavDropdown.Item> : ""}
              </NavDropdown>

              <NavDropdown
                style={{ display: isReports ? "flex" : "none" }}
                title="Reports"
                id="navbarScrollingDropdown"
                className={
                  location.pathname === "/GetContractReport" ||
                    location.pathname === "/GetOffers" ||
                    location.pathname === "/GetCredReport"
                    ? "active"
                    : ""
                }
              >
                {IsAccessible(20) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetContractReport" ? "active" : ""
                    }
                    onClick={gotoContractReport}
                  >
                    Contracts
                  </NavDropdown.Item> : ""}
                {IsAccessible(21) ?
                  <NavDropdown.Item
                    className={location.pathname === "/GetOffers" ? "active" : ""}
                    onClick={gotoOffers}
                  >
                    Offers
                  </NavDropdown.Item> : ""}
                {IsAccessible(22) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetCredReport" ? "active" : ""
                    }
                    onClick={gotoCredReport}
                  >
                    Credentialing
                  </NavDropdown.Item> : ""}
                {IsAccessible(23) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetOfferToCredenComplete"
                        ? "active"
                        : ""
                    }
                  >
                    Insurance
                  </NavDropdown.Item> : ""}


              </NavDropdown>

              <NavDropdown
                style={{ display: isAdmin ? "flex" : "none" }}
                title="Administration"
                id="navbarScrollingDropdown"
                className={
                  location.pathname === "/CompanyDetails" ||
                    location.pathname === "/UserDetails" ||
                    location.pathname === "/GetAllUserPrivilege" ||
                    location.pathname === "/RoleDetails" ||
                    location.pathname === "/CreateOffer" ||
                    location.pathname === "/GetTestData"
                    ? "active"
                    : ""
                }
              >
                {IsAccessible(6) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/CompanyDetails" ? "active" : ""
                    }
                    onClick={gotoCompanyMaster}
                  >
                    Company
                  </NavDropdown.Item>
                  : ""}
                {IsAccessible(7) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/UserDetails" ? "active" : ""
                    }
                    onClick={gotoUserMaster}
                  >
                    User
                  </NavDropdown.Item> : ""}
                {IsAccessible(8) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/GetAllUserPrivilege" ? "active" : ""
                    }
                    onClick={gotoUserPrivileges}
                  >
                    User Privileges
                  </NavDropdown.Item> : ""
                }
                {IsAccessible(9) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/RoleDetails" ? "active" : ""
                    }
                    onClick={gotoRoleMaster}
                  >
                    Role & Permissions
                  </NavDropdown.Item> : ""}
                {IsAccessible(10) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/AcceptedOffersEdit"
                        ? "active"
                        : ""
                    }
                    onClick={gotoNewOffer}
                  >
                    Offer Entry
                  </NavDropdown.Item> : ""}

                {IsAccessible(11) ?
                  <NavDropdown.Item
                    className={location.pathname === "/GetTestData"? "active": ""}
                    onClick={gotoTestData}
                  >
                    Upload Test Data
                  </NavDropdown.Item> : ""}

                  {IsAccessible(26) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/EmailSetting"
                        ? "active"
                        : ""
                    }
                    onClick={gotoEmailSetting}
                  >
                    Email Settings
                  </NavDropdown.Item> : ""}
                  {IsAccessible(27) ?
                  <NavDropdown.Item
                    className={
                      location.pathname === "/ResetPassword"
                        ? "active"
                        : ""
                    }
                    onClick={gotoCreateNewPassword}
                  >
                    Reset Password
                  </NavDropdown.Item> : ""}
              </NavDropdown>


            </Nav>
            <Navbar.Collapse
              style={{ marginRight: "2rem" }}
              className="justify-content-end"
            >
              <Navbar.Text>Company : {auth?.Userdata?.CompanyName}</Navbar.Text>
            </Navbar.Collapse>
            <Form className="d-flex NavButton">
              {/* <NavDropdown

                title={
                  auth !== null &&
                  auth?.Userdata?.FirstName +
                    " " +
                    auth?.Userdata?.LastName +
                    " (" +
                    auth?.Userdata?.RoleName +
                    ")"
                }
              > */}

              {/* <NavDropdown.Item
                  href="#"
                  ref={btnRef}
                  className="NavDrop"
                  onClick={Logout}
                >
                  <FaSignOutAlt />
                  {"  "} Log Out
                </NavDropdown.Item> */}
              {/* </NavDropdown> */}

              <Navbar.Text>
                {" "}
                {auth !== null &&
                  auth?.Userdata?.FirstName +
                  " " +
                  auth?.Userdata?.LastName +
                  " (" +
                  auth?.Userdata?.RoleName +
                  ")"}
              </Navbar.Text>
            </Form>
            <a
              href="#"
              ref={btnRef}
              className="btn btn-danger btn-sm"
              onClick={Logout}
            >
              <FaPowerOff />
              {"  "}
            </a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <CFooter />
    </>
  );
}

export default Navigationbar;
