// Toast.tsx
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
interface ToastProps {
  message: string;
  position?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left";
  autoClose?: number;
  toastKey?: number;
}

const Toast: React.FC<ToastProps> = ({
  message,
  position = "top-right",
  autoClose = 4000,
  toastKey = 0,
}) => {
  useEffect(() => {
    if (toastKey !== 0) {
      toast.success(message, {
        position,
        autoClose,
      });
    }
  }, [message, position, autoClose, toastKey]);
  return null;
};

export default Toast;
