import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi, getApiforFile } from "../../../AxiosRequest";
import swalAlert from "../../common/SwalAlert";
import { Button } from "react-bootstrap";
import Loading from "../../common/Loading";
import UploadModel from "../Models/UploadModel";
import { printDate, printDate2 } from "../../common/Util";


const TestDataUpload = () => {
 
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [showUpload, setShowUpload] = useState(false);
  const [testData, setTestData] = useState([]);
  const [allDocumentType, setAllDocumentType] = useState(
    [
      {
          "Text": "Offer",
          "Value": "Offer"
      },
      {
          "Text": "Contract",
          "Value": "Contract"
      },
      {
          "Text": "Credentialing",
          "Value": "Credentialing"
      },
      {
        "Text": "Report",
        "Value": "Report"
    }
  ]
  );
const compId="1";
  const handleRefresh = () => {
    setRefresh((oldkey) => oldkey + 1);
  };

  useEffect(() => {
    async function populateTestData() {
      setLoading(true);
      await getApi("/api/Admin/GetTestData")
        .then((response) => {
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].CreatedDate = printDate(data[index].CreatedDate);
            
          }
          setTestData(data);
          setLoading(false);
        })
        .catch((error) => {
          swalAlert(error);
        });
    }
    populateTestData();
  }, [refresh]);
  const downloadFile = async (event: any, file: any, fileName: any) => {
    event.preventDefault();
    if (file.length > 0) {
      await getApiforFile(
        "api/UploadFile/DownloadFile?FileName=" +
          fileName +
          "&&FilePath=" +
          file
      )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {});
    }
  };
  const columnDefs = [
    {
      headerName: "Created Date",
      field: "CreatedDate",
      filter: true,
      maxWidth: 180,
    },
    // {
    //   headerName: "Document Name",
    //   field: "DocumentName",
    //   filter: true,
    //   flex: 1,
    // },
    {
      field: "DocumentName",
     
      flex:1,
      cellRenderer: (data: any) => (
        
        <div>          
           <a
             href="#"
             onClick={(e) => downloadFile(e, data.data.DocumentURL+data.data.DocumentName, data.data.DocumentName)}                
                    >
             <i>{data.data.DocumentName}</i>
             </a>        
        </div>
      ),
    },
    // {
    //   headerName: "Description",
    //   field: "Description",
    //   filter: true,
    //   flex: 1,
    // },

    // {
    //   headerName: "Uploaded By",
    //   field: "CreatedBy",
    //   filter: true,
    //   flex: 1,
    // },
    {
      headerName: "Document Type",
      field: "DocumentTypeId",
      filter: true,
      flex: 1,
    }
  ];


  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);
  const Columns = ["CreatedDate", "DocumentName","download", "Description", "CreatedBy", "DocumentTypeId"];
  const handleUploadShow = useCallback(() => {
    setShowUpload(true);
  }, []);
  const handleUploadClose = useCallback(() => {
    setShowUpload(false);
  }, []);
  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title="Test Data" />

        <div className="col-md-12">
          <div className="justify-content-end">
            <Button
              size="sm"
              variant="primary"
              onClick={handleUploadShow}
              style={{ marginLeft: "15px" }}
            >
              Upload Data
            </Button>
          </div>
        </div>
        <div className="container-fluid">
          {testData && (
            <CAgGrid
              Columns={Columns}
              rowData={testData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              isShowCreateButton={false}
              isShowExportButton={false}
              isShowQuickFilter={false}
              fileName="Test Data"
            />
          )}
        </div>
      </div>
      {showUpload && (
        <UploadModel
          showUpload={showUpload}
          // contractId={paramContractID}
          companyId={compId}
          handleRefresh={handleRefresh}
          handleUploadClose={handleUploadClose}
          title="Upload Test Data"
          ///UploadOtherDocument={true}
          UploadTestDocument={true}
          otherDocumentType={allDocumentType}
        // offerId={paramOfferID}
        />
      )}


    </>
  );
};

export default TestDataUpload;
