import { useContext, useState } from "react";
import useAutoLogout from "../AutoLogout/useAutoLogout";
import AuthContext from "./AuthProvider";
import { GDSContextType } from "./@types.usr";
function LogoutMessage(props: any) {
  const { saveContext } = useContext(AuthContext) as GDSContextType;
  const [isVisible] = useState(true);
  const objdata = {
    Userdata: {
      CompanyName: "",
      EmailId: "",
      FirstName: "",
      IsAdmin: false,
      LastName: "",
      LoginId: "",
      MobileNo: "",
      PermissionID: 0,
      PermissionName: "",
      RoleID: 0,
      RoleName: "",
      UserId: 0,
    },
    UserPermission: {
      Key: 0,
      Value: "",
    },
  };

  const timer = useAutoLogout(300);
  if (timer === 0) {
    props.ForceLogout();
    saveContext(objdata);
  } else if (timer < 10) {
    return (
      <div className={`logout-message ${isVisible ? "visible" : ""}`}>
        In {timer} seconds you will be logged out ! &nbsp;{" "}
      </div>
    );
  }

  return <div></div>;
}

export default LogoutMessage;
