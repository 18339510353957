import { useCallback, useEffect, useMemo, useState } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import OfferModel from "../Models/OfferModel";
import { Button } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { printDate } from "../../common/Util";
import Loading from "../../common/Loading";
import { encryptString } from "../../common/cryptoUtils";


interface IContracts {
  OfferID: number;
  ContractID: number;
  CompanyID: number;
  ContractStatus: string;
  ForCause: string;
  ForConvenienceofPractice: string;
  ForConvenienceofOD: string;
  NonCompetition: string;
  NonSolicitationOfPractive: string;
  NonSolicitationofPatients: string;
  NonSolicitationofEmployees: string;
  DefSolicit: string;
  DefSolicit1: string;
  DefSolicit2: string;
  NonInterfernce: string;
  NonDisparagement: string;
  RepWarranties: string;
  RepWarranties1: string;
  RepWarranties2: string;
  RepWarranties3: string;
  RepWarranties4: string;
  RepWarranties5: string;
  RepWarranties6: string;
  RepWarranties7: string;
  RepWarranties8: string;
  CreatedDate: Date;
  CreatedBy: string;
  UpdatedDate: Date;
  UpdatedBy: string;
  FldOfferNavigation: {};
}

const ContractIndex = () => {
  // const [title, setTitle] = useState("");
  const [PageTitle, setPageTitle] = useState("");
  // const [contract_ID, setcontract_ID] = useState("");
  // const [company_ID, setcompany_ID] = useState("");
  const [loading, setLoading] = useState(false);
  // const [editContractData, seteditContractData] = useState<IContracts[]>([]);
  const [refresh, setrefresh] = useState(0);
  // const [show, setShow] = useState(false);
  // const [isUpdate, setIsUpdate] = useState(false);

  // const handleShow = useCallback(() => {
  //   setShow(true);
  // }, []);
  // const handleClose = useCallback(() => {
  //   setShow(false);
  // }, []);

  // const [showUpload, setShowUpload] = useState(false);
  // const handleUploadShow = useCallback(() => {
  //   setShowUpload(true);
  // }, []);
  // const handleUploadClose = useCallback(() => {
  //   setShowUpload(false);
  // }, []);

  const [contractData, setContractData] = useState<IContracts[]>([]);
  const urlElementsAction = window.location.href.split("/")[3];

  useEffect(() => {
    async function populateOffers() {
      setLoading(true);
      let url = "";
      switch (urlElementsAction) {
        case "GetNewContracts":
          url = "/api/Contracts/getNewContract";
          setPageTitle("New Contracts");
          break;
        case "GetInProgressContracts":
          url = "/api/Contracts/getInProgressContract";
          setPageTitle("In Progress Contracts");
          break;
        default:
          url = "/api/Contracts/getallContract";
          setPageTitle("All Contracts");
          break;
      }

      await getApi(url)
        .then((response) => {
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
          }
          setContractData(data);

          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh, urlElementsAction]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  const Columns = [
    "EmployeeNumber",
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    "EmployeeLocation",
    "LocationState",
    "ContractStatus",
  ];

  const columnDefs = [
    {
      headerName: "Employee #",
      field: "EmployeeNumber",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "Address",
      field: "EmployeeLocation",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      headerName: "State",
      field: "LocationState",
      filter: true,
      flex: 1,
      resizable: false,
    },
    {
      field: "View Details",
      flex: 1,
      resizable: false,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            onClick={() => OpenOfferDetailView(data)}
          >
            <i
              title={
                "View Details for " +
                data.data.EmployeeFirstName +
                " " +
                data.data.EmployeeLastName
              }
            >
              <FaInfoCircle />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      field: "Action",
      flex: 1,
      cellRenderer: (data: any) => (
        <div>
          <Button
            className="btnLink"
            onClick={() => OpenContractView(data)}
            variant="link"
          >
            <i>View/Edit</i>
          </Button>
        </div>
      ),
    },

    {
      headerName: "Status",
      field: "ContractStatus",
      filter: true,
      flex: 1,
      resizable: true,
    },
  ];

  let navigate = useNavigate();
  const OpenOfferDetailView = (obj: any) => {   
    let paramOfferID = encryptString(obj?.data?.OfferID);
       let paramCompanyID = encryptString(obj?.data?.CompanyID);
    let path =
      "/OfferDetails/" +
      paramOfferID +
          "/" +
      paramCompanyID;
    navigate(path);
  };
  const OpenContractView = (obj: any) => {
    let paramOfferID = encryptString(obj?.data?.OfferID);
    let paramContractID = encryptString(obj?.data?.ContractID);
    let paramCompanyID = encryptString(obj?.data?.CompanyID);

    let path =
      "/Contract/" +
      paramOfferID +
      "/" +
      paramContractID +
      "/" +
      paramCompanyID;
    navigate(path);
  };


  // const OpenModel = (obj: any) => {
  //   if (typeof obj === "undefined") {
  //   } else {
  //     seteditContractData(obj.data);
  //     setIsUpdate(true);
  //     handleShow();
  //     setTitle("View Offer Details");
  //   }
  // };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  return (
    <>
      {" "}
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title={PageTitle} />
        <div className="container-fluid">
          {contractData && (
            <CAgGrid
              Columns={Columns}
              rowData={contractData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              // OpenModel={OpenModel}
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="Contracts"
            />
          )}
        </div>
      </div>
      {/* {show && (
        <OfferModel
          show={show}
          handleClose={handleClose}
          editData={editContractData}
          seteditData={seteditContractData}
          isUpdate={isUpdate}
          handleRefresh={handleRefresh}
          title={title}
          isModelDisable={true}
          isButtonHideFromModel={true}
        />
      )} */}
    </>
  );
};

export default ContractIndex;
