import React, { useEffect, useMemo, useState } from "react";
import Loading from "../../common/Loading";
import { CHeading } from "../../CHeading";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { getApi, postApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import Swal from "sweetalert2";
import {  useParams } from "react-router-dom";
import { decryptString } from "../../common/cryptoUtils";
import CAgGrid from "../../common/CAgGrid";
import MultiSelect, { Option } from "../../common/MultiSelect";


type typeParams = {
  paramUserID: any;
  paramCompanyID: any;
  paramRoleType:any
};
const UserPermission = () => {
  const [loading, setLoading] = useState(false);
  const [UserList, setUserList] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [PermissionItemList, setPermissionItemList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [UserPermissions, setUserPermissions] = useState([]);
  const [refresh, setrefresh] = useState(0);
  const [ListToDelete, setListToDelete] = useState("");
  const [optionSelected, setSelected] = useState<Option[] | null>();
  const handleChange = (selected: Option[]) => {
    setSelected(selected);
  };
  const iniEditData: any = {
    UserID: "",
    RoleType: "",
    RoleItems: "",
    CompanyId: ""

  };
  const { paramUserID, paramCompanyID,paramRoleType } =
    useParams<typeParams>();

  const [EditData, setEditData] = useState(iniEditData);

  const handleRefresh = () => {
    debugger;
    iniEditData.UserID = EditData.UserID;
    iniEditData.RoleType = EditData.RoleType;
    setEditData(iniEditData);
    setSelected(null);
    setrefresh((oldkey) => oldkey + 1);
  }

  
  useEffect(() => {
    async function populateUsers() {
      await getApi("/api/Common/getAllUser")
        .then((response) => {
          setUserList(response.data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    async function populateUserPrivilege(UserId: any) {
      setLoading(true);
      let url = "/api/Users/GetAllUserPrivilege?UserId=" + UserId;

      await getApi(url)
        .then((response) => {
          let data = response.data;
          setUserPermissions(data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let IsCancel = false;
    if (!IsCancel) {
      if (
        paramUserID !== undefined &&
        paramUserID !== null
      ) {
        let decryptedParamUserID = decryptString(paramUserID);
        let decryptedParamRoleType = decryptString(paramRoleType);
        let decryptedParamCompanyID = decryptString(paramCompanyID);
        populateUsers();
        populateUserPrivilege(decryptedParamUserID);
        EditData.CompanyId = decryptedParamCompanyID;
        EditData.UserID = decryptedParamUserID;
        debugger;
        //EditData.RoleType = EditData.RoleType===""?(decryptedParamRoleType==="0"?"5":decryptedParamRoleType):EditData.RoleType;
        EditData.RoleType = EditData.RoleType || (decryptedParamRoleType === "0" ? "5" : decryptedParamRoleType);

        iniEditData.UserID = decryptedParamUserID;
        setEditData(EditData);
      } else {
        populateUsers();
      }
    }
    return () => {
      IsCancel = true;
    };
  }, [paramUserID, refresh]);




  useEffect(() => {

    async function populateUserRoles() {
      await getApi("/api/Common/getUserRoles")
        .then((response) => {
          setRoleList(response.data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    async function populateUserPermission() {
      await getApi("/api/Common/getUserPermissionList")
        .then((response) => {
          setPermissionItemList(response.data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateUserPermission();
      populateUserRoles();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      debugger;
      EditData.RoleItems = optionSelected?.map((item: any) => item.value).join(',');
      setEditData(EditData);
      // Swal.fire({
      //   timer: 2500,
      //   title: "Do you want to Submit?",
      //   showDenyButton: true,
      //   showCancelButton: true,
      //   confirmButtonText: "Submit",
      //   denyButtonText: `Don't Submit`,
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      await postApi("/api/Users/AddNewPermission", EditData)
        .then(async (response) => {

          Swal.fire(response.data, "", "success");
          //setEditData(EditData);
          handleRefresh(); 
        })
        .catch(async (error) => {
          SwalAlert(error);
        })
        .finally(async () => { });
      // } else if (result.isDenied) {
      //   Swal.fire("Changes are not saved", "", "info");
    }
    //   });
    // }
    setValidated(true);
  };
  const handleDelete = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    debugger;
    if (ListToDelete == null || ListToDelete == '') {
      Swal.fire("Please select any record.", "", "info");
      return false;
    }
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {

      let param = {
        UserId: decryptString(paramUserID),
        roleItems: ListToDelete,

      };

      Swal.fire({
        timer: 2500,
        title: "Do you want to Delete?",
        icon: "info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        // denyButtonText: `Don't Delete`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postApi("/api/Users/RemovePermission", null, param)
            .then(async (response) => {
              Swal.fire(response.data, "", "success");
              handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => { });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
    setValidated(true);
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);
  const columnDefs = [
    {

      maxWidth: 100,
      headerCheckboxSelection: true,
      checkboxSelection: (params: any) => {
        return !!params.data;
      },

    },
    {
      headerName: "Permission",
      field: "PermissionName",
      filter: true,
      flex: 1
    },
    {
      headerName: "Role ",
      field: "RoleName",
      filter: true,
      flex: 1
    },


  ];

  const Columns = [
    "PermissionName",
    "RoleName",


  ];

  const onSelectionChanged = (event: any) => {
    debugger;
    let selectedData = event.api.getSelectedRows();
    var ListToDeleteString = selectedData.map((User: any) => User.PermissionID).join(',');
    setListToDelete(ListToDeleteString);
  };


  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title="User Permissions" />

        <Container>
          <Row>
            <Col md={{ span: 4 }}>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >

                <Form.Group as={Col} md="10" controlId="validationCustom02">
                  <Form.Label className="required">Users</Form.Label>
                  <Form.Select
                    required
                    disabled
                    aria-label="Select User"
                    onChange={(e) =>
                      setEditData({
                        ...EditData,
                        UserID: e.target.value,
                      })
                    }
                    value={EditData.UserID}
                  >

                    {UserList.map((data: any) => (
                      <option key={data.code} value={data.code}>
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="10" controlId="validationCustom01">
                  <Form.Label className="required">Role Type</Form.Label>
                  <Form.Select

                    required
                    aria-label="Role Type"


                    onChange={(e) =>
                      setEditData({
                        ...EditData,
                        RoleType: e.target.value,
                      })
                    }
                    value={EditData.RoleType}
                  >

                    {RoleList.map((data: any) => (
                      <option key={data.code} value={data.code}>
                        {data.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="10" controlId="validationCustom01">
                  <Form.Label className="required">Role Items</Form.Label>

                  {/* <Select options={PermissionItemList}  name="RoleItems" className="basic-multi-select" placeholder="Choose your Role items"  onChange={(e) =>
                        setEditData({
                          ...EditData,
                          RoleItems: e.target.value,
                        })
                      }
                      value={EditData.RoleItems} /> */}

                  <MultiSelect
                    key="example_id"
                    name="RoleItems"
                    options={PermissionItemList}
                    onChange={handleChange}
                    value={optionSelected}
                    isSelectAll={true}
                    menuPlacement={"bottom"}
                    className="basic-multi-select"
                    placeholder="Choose your Role items"
                    required
                  />

                  {/* <Form.Select

                      required
                      aria-label="Role Items"
                      onChange={(e) =>
                        setEditData({
                          ...EditData,
                          RoleItems: e.target.value,
                        })
                      }
                      value={EditData.RoleItems}
                    >

                      {PermissionItemList.map((data: any) => (
                        <option key={data.code} value={data.code}>
                          {data.name}
                        </option>
                      ))}
                    </Form.Select> */}
                </Form.Group>

                <Col md={{ span: 6, offset: 4 }}> <div className="mt-2 col-md-6" style={{ float: "right" }}>
                  <InputGroup>
                    <Button size="sm" className="btn btn-primary" type="submit">
                      Submit
                    </Button>
                    <Button size="sm" className=" btn btn-danger" type="button" onClick={handleRefresh} >
                      Cancel
                    </Button>
                  </InputGroup>
                </div>
                </Col>
              </Form>
            </Col>

            <div className="col-md-8">

              <div style={{ float: "right" }}>
                <Button size="sm" className=" btn btn-danger" type="button" onClick={handleDelete} >
                  Delete
                </Button>
              </div>

              {UserPermissions && (
                <CAgGrid
                  Columns={Columns}
                  rowData={UserPermissions}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  //OpenModel={OpenModel}
                  isShowQuickFilter={true}
                  rowSelectionType={"multiple"}
                  fileName="User Permissions"
                  onSelectionChanged={onSelectionChanged}

                />
              )}

            </div>
          </Row>
        </Container>

      </div>

    </>
  );
}
export default UserPermission;