import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { postApi } from "../../../AxiosRequest";
import Swal from "sweetalert2";
import SwalAlert from "../../common/SwalAlert";
import AuthContext from "../../common/AuthProvider";
import { GDSContextType } from "../../common/@types.usr";
import { useNavigate } from "react-router-dom";
import { encryptString } from "../../common/cryptoUtils";
import { printDate2 } from "../../common/Util";

function OfferModel(props: any) {
  const { auth } = useContext(AuthContext) as GDSContextType;
  const [isAdmin] = useState(
    auth?.UserPermission.length > 0
      ? auth?.UserPermission?.some((obj: any) => obj.Key === 3)
        ? true
        : false
      : false
  );
  const UpdateOfferStatus = async (
    statusval: string,
    event: {
      currentTarget: any;
      preventDefault: () => void;
    }
  ) => {
    event.preventDefault();

    var msg = "";
    switch (statusval) {
      case "1":
        msg = "Do you want to start Primary Source Verification?";
        break;
      case "2":
        msg = "Do you want to complete Primary Source Verification?";
        break;
      case "4":
        msg = "Do you want to start Full Credentialing?";
        break;
      case "5":
        msg = "Do you want to complete Full Credentialing?";
        break;
    }
    
   
    Swal.fire({
      // timer: 2500,
      title: msg,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let parms = {
          offerId: props.editData?.OfferID,
          userId: auth?.Userdata?.UserId,
          status: statusval,
          employeeNumber: props.editData?.EmployeeNumber,
        };
        await postApi("/api/Credentialing/UpdateOffersStatus", null, parms)
          .then(async (response) => {
            props.handleClose();
            props.handleRefresh();
            props.setMessage(response.data);
            props.handleClick();
          })
          .catch(async (error) => {
            SwalAlert(error);
          })
          .finally(async () => {});
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved.", "", "info");
      }
    });
  };
  let navigate = useNavigate();

  const OpenContractView = (obj: any) => {
    let paramOfferID = encryptString(obj);

    let path = "/GetOffers/" + paramOfferID;
    navigate(path);
  };
  return (
    <Modal
      size="xl"
      show={props.show}
      onHide={props.handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
        &nbsp;
        <Button
          className="btn btn-sm btn-success"
          onClick={() => OpenContractView(props.editData?.OfferID)}
          style={{ display: isAdmin ? "flex" : "none" }}
        >
          <i>Detail View/Edit</i>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-10">
                  <input type="hidden" value={props.editData?.OfferID} />
                  <div className="row">
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Form.Label>Employee Number</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeNumber || ""}
                        placeholder="Employee Number:"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeNumber: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom02">
                      <Form.Label>First Name:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeFirstName || ""}
                        placeholder="First Name"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeFirstName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom03">
                      <Form.Label>Last Name:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeLastName || ""}
                        placeholder="Last Name"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeLastName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                      <Form.Label>State:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.LocationState || ""}
                        placeholder="State"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            LocationState: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="mt-2">
                    <div style={{ marginTop: "28px" }}>
                      <Button
                        size="sm"
                        variant="warning"
                        type="button"
                        onClick={(e) => UpdateOfferStatus("1", e)}
                        style={{
                          display: props?.isButtonHideFromModel
                            ? "none"
                            : props.editData?.CredStatusId === null
                            ? "block"
                            : "none",
                        }}
                      >
                        PSV Start
                      </Button>
                      <Button
                        size="sm"
                        type="button"
                        variant="success"
                        onClick={(e) => UpdateOfferStatus("2", e)}
                        style={{
                          display: props?.isButtonHideFromModel
                            ? "none"
                            : props?.editData?.CredStatusId === "1"
                            ? "block"
                            : "none",
                        }}
                      >
                        PSV Complete
                      </Button>
                      <Button
                        size="sm"
                        type="button"
                        variant="success"
                        onClick={(e) => UpdateOfferStatus("4", e)}
                        style={{
                          display: props?.isButtonHideFromModel
                            ? "none"
                            : props?.editData?.CredStatusId === "3"
                            ? "block"
                            : "none",
                        }}
                      >
                        Full Credentialing Start
                      </Button>
                      <Button
                        size="sm"
                        type="button"
                        variant="success"
                        onClick={(e) => UpdateOfferStatus("5", e)}
                        style={{
                          display: props?.isButtonHideFromModel
                            ? "none"
                            : props?.editData?.CredStatusId === "4"
                            ? "block"
                            : "none",
                        }}
                      >
                        Full Credentialing Complete
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col-md-4">
                  <Card>
                    <Card.Header>Compensation</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom05"
                        >
                          <Form.Label>Daily Rate:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={props.editData?.DailyRate || ""}
                            placeholder="Daily Rate"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                DailyRate: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom06"
                        >
                          <Form.Label>Base Salary:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={props.editData?.BaseSalary || ""}
                            placeholder="Base Salary"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                BaseSalary: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom07"
                        >
                          <Form.Label>Production Bonus %:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={
                              props.editData?.ODContract_ProductionPercentage ||
                              ""
                            }
                            placeholder="Production Bonus"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_ProductionPercentage: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom08"
                        >
                          <Form.Label>Production Threshold:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={
                              props.editData?.ODContract_ProductionThreshold ||
                              ""
                            }
                            placeholder="Production Threshold"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_ProductionThreshold: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom09"
                        >
                          <Form.Label>Production Bonus Frequency:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={
                              props.editData
                                ?.ODContract_ProductionPaymentFrequency || ""
                            }
                            placeholder="Production Bonus Frequency"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_ProductionPaymentFrequency:
                                  e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-md-4">
                  <Card>
                    <Card.Header>Benefits</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom12"
                        >
                          <Form.Label>PTO Days:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={props.editData?.ODContract_PTODays || ""}
                            placeholder="PTO Days"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_PTODays: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom13"
                        >
                          <Form.Label>CE Days:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={props.editData?.ODContract_PaidCEDays || ""}
                            placeholder="CE Days"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_PaidCEDays: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom14"
                        >
                          <Form.Label>Association Dues %:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={
                              props.editData?.ODContract_AOADuesPercentage || ""
                            }
                            placeholder="Association Dues %"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_AOADuesPercentage: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom15"
                        >
                          <Form.Label>State Dues %:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={
                              props.editData?.ODContract_StateDuesPercentage ||
                              ""
                            }
                            placeholder="State Dues %"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_StateDuesPercentage: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                          <Form.Check
                            label="Licensures Fees:"
                            checked={
                              props.editData?.ODContract_LicensureDues || false
                            }
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_LicensureDues: e.target.checked,
                              })
                            }
                          ></Form.Check>
                          <Form.Check
                            label="Enrolled in Benefits:"
                            checked={
                              props.editData
                                ?.ODContract_EnrolledInHealthBenefits || false
                            }
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_EnrolledInHealthBenefits:
                                  e.target.checked,
                              })
                            }
                          ></Form.Check>
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-md-4">
                  <Card>
                    <Card.Header>Terms</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom16"
                        >
                          <Form.Label>Effective Date:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="date"
                            value={printDate2(props.editData?.EffectiveDate)}
                            placeholder="Effective Date"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_PTODays: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom17"
                        >
                          <Form.Label>Initial Term:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={props.editData?.InitialTerm || ""}
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_PaidCEDays: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom18"
                        >
                          <Form.Label>Renewal Term:</Form.Label>
                          <Form.Control
                            disabled={props?.isModelDisable}
                            required
                            type="text"
                            value={
                              props.editData?.ODContract_AOADuesPercentage || ""
                            }
                            placeholder="Renewal Term"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_AOADuesPercentage: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-6">
                  <Form.Group as={Col} md="12" controlId="validationCustom17">
                    <Form.Label>Schedule:</Form.Label>
                    <Form.Control
                      disabled={props?.isModelDisable}
                      required
                      type="text"
                      value={props.editData?.ODContract_ContractSchedule || ""}
                      placeholder="Schedule"
                      onChange={(e) =>
                        props.seteditData({
                          ...props.editData,
                          ODContract_ContractSchedule: e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom19">
                    <Form.Label>Location:</Form.Label>
                    <Form.Control
                      disabled={props?.isModelDisable}
                      required
                      type="text"
                      value={props.editData?.EmployeeLocation || ""}
                      placeholder="Location"
                      onChange={(e) =>
                        props.seteditData({
                          ...props.editData,
                          EmployeeLocation: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6">
                  <Form.Group as={Col} md="12" controlId="validationCustom18">
                    <Form.Label>Schedule full description:</Form.Label>
                    <Form.Control
                      disabled={props?.isModelDisable}
                      required
                      type="text"
                      value={
                        props.editData
                          ?.ODContract_ContractScheduleFullDescription || ""
                      }
                      placeholder="Schedule full description"
                      onChange={(e) =>
                        props.seteditData({
                          ...props.editData,
                          ODContract_ContractScheduleFullDescription:
                            e.target.value,
                        })
                      }
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="12" controlId="validationCustom20">
                    <Form.Label>Legal Entity:</Form.Label>
                    <Form.Control
                      disabled={props?.isModelDisable}
                      required
                      type="text"
                      value={props.editData?.ODContract_LegalEntity || ""}
                      placeholder="Legal Entity"
                      onChange={(e) =>
                        props.seteditData({
                          ...props.editData,
                          ODContract_LegalEntity: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default OfferModel;
