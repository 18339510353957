import { Navigate, Outlet, useLocation } from "react-router-dom";
import { clearStorage, isLoggedIn } from "./commponents/common/Util";

function ProtectedRoute({ redirectPath = "/Login" }: any, { childern }: any) {
  
  const location = useLocation();
  if (isLoggedIn() === false) {
    clearStorage();
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }
  return childern || <Outlet />;
}

export default ProtectedRoute;
