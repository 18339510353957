import { Modal } from "react-bootstrap";
import UploadAllFile from "../../common/UploadAllFile";
const UploadModel = (props: any) => {  
  console.log("🚀 ~ file: UploadModel.tsx:4 ~ UploadModel ~ props:", props)
  return (
    <>
      {props.showUpload && (
        <Modal
          show={props.showUpload}
          onHide={props.handleUploadClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{props?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UploadAllFile
              contractId={props?.contractId}
              companyId={props?.companyId}
              handleRefresh={props?.handleRefresh}
              handleUploadClose={props?.handleUploadClose}
              UploadOtherDocument={props?.UploadOtherDocument}
              UploadOfferDocument={props?.UploadOfferDocument}
              UploadTestDocument={props?.UploadTestDocument}
              otherDocumentType={props?.otherDocumentType}
              offerId={props?.offerId}
              InsProof={props?.InsProof}
              OnBoard={props?.OnBoard}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default UploadModel;
