import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { postApi } from "../../../AxiosRequest";
import Swal from "sweetalert2";
import SwalAlert from "../../common/SwalAlert";

function ForgetPasswordModel(props: any) {
  const [validated, setValidated] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [UserNameEnable, setUserNameEnable] = useState(false);

  const handleReset = () => {    
    setIsReset(true);
  };

  const ResetSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
  
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        // timer: 2500,
        title: "Do you want to reset your password?",
        showDenyButton: true,
        icon:"info",
        // showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then(async (result) => {
        if (result.isConfirmed) {          
          let param = {
            UserName: UserForgetPassword.UserName
          };
          await postApi("/api/auth/ForgetPassword",null, param)
            .then(async (response) => { 
        
              console.log("🚀 ~ file: ForgetPasswordModel.tsx:46 ~ .then ~ response:", response)
              if(response.data.data=="Success"){
                UserForgetPassword.UserId=response.data.UserId;
                UserForgetPassword.MagicCode=response.data.MagicCode;
                setUserForgetPassword(UserForgetPassword);               
                handleReset();
                setUserNameEnable(true);
                Swal.fire("Temporary password has been sent to your registered Email. Please enter the same.", "", "success");
              }else{
              props.handleClose();
              Swal.fire(response.data, "","error");
              props.handleRefresh();
              }
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("No password reset", "", "info");
        }
      });
    }
    setValidated(true);
  };
  const [UserForgetPassword, setUserForgetPassword] = useState({
    UserId: "",
    UserName: "",
    Password: "",
    ConfirmPassword:"",
    MagicCode:""
  });
  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();

  if(UserForgetPassword.MagicCode!==UserForgetPassword.Password){
     Swal.fire("Please enter the valid temporary password.", "", "info");
     return false;
  }
  
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
               
          let param = {
            UserId: UserForgetPassword.UserId,
            Password:UserForgetPassword.Password,
            magicCode:UserForgetPassword.MagicCode
          };
          await postApi("/api/auth/ChangePassword",null, param)
            .then(async (response) => { 
              console.log("🚀 ~ file: ForgetPasswordModel.tsx:46 ~ .then ~ response:", response)
              if(response.data=="Success"){
                
                handleReset();
                props.handleClose();
                Swal.fire("Password has been changed successfully, Please login again.", "", "success");
             
              }else{              
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
              }
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
      
    }
    setValidated(true);
  };

   function ResetEnable() {
    setIsReset(true);
  }
  return (
    <Modal
      size="sm"
      show={props.show}
      onHide={props.handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={isReset === true ?  handleSubmit:ResetSubmit }
        >
          <div className="container">
            <div className="col-lg-12">
            <Form.Control
                      required
                      type="hidden"
                      value={UserForgetPassword.MagicCode}                    
                      onChange={(e) =>
                        setUserForgetPassword({...UserForgetPassword,  UserName: e.target.value})
                      }
                    />
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>User name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={UserForgetPassword.UserName}
                      placeholder="User name"
                      onChange={(e) =>
                        setUserForgetPassword({...UserForgetPassword,  UserName: e.target.value})
                      }
                      disabled={UserNameEnable}
                    />
                  </Form.Group> 
                  {isReset && ( <div id="ResetPassword" >          
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      value={UserForgetPassword.Password}
                      placeholder="New Password"
                      onChange={(e) =>
                        setUserForgetPassword({...UserForgetPassword,  Password: e.target.value})
                      }
                    />
                  </Form.Group>  
                 
                  </div>)  } 
          <div className="mt-2 btn-Model-margin " style={{float:"right"}}>
          
            <Button size="sm" type="submit"  >
               {isReset === true ?  "Submit":"Reset Password" }
            </Button>
          </div></div>
            </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ForgetPasswordModel;
