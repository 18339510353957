import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Pie } from "react-chartjs-2";
Chart.register(CategoryScale);

function PieChart(props: any) {
  return (
    <div className="chart-container">
      <h3 style={{ textAlign: "center" }}>{props.Title}</h3>
      <Pie
        data={props.data}
        options={{
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
          },
        }}
      />
    </div>
  );
}
export default PieChart;
