import { createContext, useState } from "react";
import { getDatafromStorage } from "./Util";
import { GDSContextType, IUser } from "./@types.usr";

const AuthContext = createContext<GDSContextType | null>(null);

export const AuthProvider = ({ children }: any) => {
  const [auth, setAuth] = useState<IUser | any>({
    Userdata: getDatafromStorage("Userdata"),
    UserPermission: getDatafromStorage("UserPermission"),
  });

  const saveContext = (data: IUser) => {
    const newTodo: IUser = {
      Userdata: data.Userdata,
      UserPermission: data.UserPermission,
    };
    setAuth(newTodo);
  };

  return (
    <AuthContext.Provider value={{ auth, saveContext }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
