import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi, postApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import { Button } from "react-bootstrap";
import { FaEdit, FaFile, FaInfoCircle, FaPlayCircle, FaTrashAlt } from "react-icons/fa";
import Loading from "../../common/Loading";
import Toast from "../../common/Toast";
import {
  checkDateIsNull,
  printDate,
  printDateMonthName,
} from "../../common/Util";
import Swal from "sweetalert2";
import { GDSContextType } from "../../common/@types.usr";
import AuthContext from "../../common/AuthProvider";
import LimitedFieldOfferModel from "../Models/LimitedFieldOfferModel";
import UploadModelOffer from "../Models/DocumentModalOffer";

interface IOffers {
  OfferDate: Date;
  CompanyName: string;
  LocationState: string;
  OfferID: number;
  CompanyID: number;
  ContractID: number;
  EmployeeNumber: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  EmployeeLocation: string;
  BaseSalary: number;
  DailyRate: number;
  ODContract_ProductionPercentage: number;
  ODContract_EnrolledInHealthBenefits: string;
  ODContract_PTODays: number;
  ODContract_PaidCEDays: number;
  ODContract_LegalEntity: string;
  ODContract_AgreementType: string;
  OriginalHireDate: Date;
  HireDate: Date;
  ODContract_RenewalDate: Date;
  ODContract_AutoRenew: boolean;
  ODContract_ContractSchedule: string;
  ODContract_ContractScheduleFullDescription: string;
  ODContract_ProductionThreshold: number;
  ODContract_ProductionPaymentFrequency: string;
  ODContract_AOADuesPercentage: number;
  ODContract_StateDuesPercentage: number;
  ODContract_LicensureDues: number;
  ODContract_MultipleLocations: string;
  ODContract_SecondaryLocation: string;
  Region: string;
  EmployeeGender: string;
  EmployeeBirthDate: Date;
  SeniorityDate: Date;
  TerminationDate: Date;
  Job: string;
  PayType: string;
  PayClass: string;
  ODContract_TuitionReimbursement: number;
  ODContract_CarTravelAllowanceMonthly: number;
  ODContract_SignOnBonus: number;
  ODContract_CEReimbursement: number;
  ODContract_PaidHolidays: number;
  ODContract_PLIProvision: boolean;
}

const Credentialing = () => {
  const { auth } = useContext(AuthContext) as GDSContextType;
  const [loading, setloading] = useState(false);
  const [credentialingData, setCredentialingData] = useState<IOffers[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [message, setMessage] = useState("");
  const [toastKey, setToastKey] = useState<number>(0);
  const [show, setShow] = useState(false);
  const [DocShow, setDocShow]= useState(false); 
  //model open functionality
   
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const [title, setTitle] = useState("");
  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const OpenModel = (obj: any) => {
    if (typeof obj === "undefined") {
      handleShow();
      setTitle("Create Offers");
    } else {
      seteditOfferData(obj.data);
      handleShow();
      setTitle("View Offer Details");
    }
  };
  const [editOfferData, seteditOfferData] = useState<IOffers[]>([]);
  //
/* Doc Modal Region */
const handleDocShow = useCallback(() => {
  setDocShow(true);
}, []);
const handleDocClose = useCallback(() => {
  setDocShow(false);
}, []);
const [DocTitle, setDocTitle] = useState("");
const OpenDocModel = (obj: any) => {
  if (typeof obj === "undefined") {
  } else {   
    seteditOfferData(obj.data);
    handleDocShow();
    setDocTitle("Documents");
  }
};
/* Doc Modal Region */

  useEffect(() => {
    async function populateOffers() {
      setloading(true);
      await getApi("/api/Credentialing/getAllPSVRequired")
        .then((response) => {
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
            data[index].CredStartDate = printDate(data[index].CredStartDate);
            data[index].CredCompletionDate = printDate(
              data[index].CredCompletionDate
            );
          }
          setCredentialingData(data);
          setloading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  const columnDefs = [
    {
      headerName: "Employee #",
      field: "EmployeeNumber",
      filter: true,
      flex:1
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      flex:1
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      flex:1
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      maxWidth: 120,
    },
    {
      headerName: "Address",
      field: "EmployeeLocation",
      filter: true,
      flex:1
    },
    {
      headerName: "State",
      field: "LocationState",
      filter: true,
      maxWidth: 90,
    },
    {
      field: "View Details",
      maxWidth: 120,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            onClick={() => OpenModel(data)}
          //onClick={() => OpenOfferDetailView(data)}
          >
            <i
              title={
                "View Details for " +
                data.data.EmployeeFirstName +
                " " +
                data.data.EmployeeLastName
              }
            >
              <FaInfoCircle />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      field: "Document",
      flex: 1,
      resizable: false,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            onClick={() => OpenDocModel(data)}
          >
            <i>
              <FaFile />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    // {
    //   headerName: "Credentialing Status",
    //   field: "CredStatus",
    //   filter: true,
    //   maxWidth: 180,
    // },
    {
      field: "Action",
      maxWidth: 100,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "2px" }}
            size="sm"
            className="btn btn-primary btnLinkOther"
            onClick={(e) => UpdateOfferStatus("1", e, data)}
          >
            PSV Start
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "Start Date",
      field: "CredStartDate",
      filter: true,
      maxWidth: 120,
    },
   
  ];

  const UpdateOfferStatus = async (
    statusval: string,
    event: {
      currentTarget: any;
      preventDefault: () => void;
    },
    obj: any
  ) => {
    event.preventDefault();
    var msg = "Do you want to start Primary Source Verification?";

    Swal.fire({
      // timer: 2500,
      title: msg,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result) => {
      if (result.isConfirmed) {

        let parms = {
          offerId: obj.data?.OfferID,
          userId: auth?.Userdata?.UserId,
          status: statusval,
          employeeNumber: obj.data?.EmployeeNumber,
        };
        await postApi("/api/Credentialing/UpdateOffersStatus", null, parms)
          .then(async (response) => {
            handleRefresh();
            setMessage(response.data);
            handleClick();
          })
          .catch(async (error) => {
            SwalAlert(error);
          })
          .finally(async () => { });
      }
    });
  };



  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const Columns = [
    "EmployeeNumber",
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    "EmployeeLocation",
    "LocationState",
    "CredStatus",
    "CredStartDate",
    "CredCompletionDate",
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <Toast message={message} toastKey={toastKey} />
        <CHeading Title="PSV Start" />
        <div className="container-fluid">
          {credentialingData && (
            <CAgGrid
              Columns={Columns}
              rowData={credentialingData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="Credentialing"
            />
          )}
          {show && (
            <LimitedFieldOfferModel
              show={show}
              handleClose={handleClose}
              editData={editOfferData}
              handleRefresh={handleRefresh}
              title={title}
              isModelDisable={true}
              isButtonHideFromModel={true}
            />
          )}
           {DocShow && (
        <UploadModelOffer
          show={DocShow}
          handleClose={handleDocClose}
          editData={editOfferData}          
          title={DocTitle}
          isModelDisable={true}
          isButtonHideFromModel={true}
          
        />
      )}
      
        </div>
      </div>
    </>
  );
};

export default Credentialing;
