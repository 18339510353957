import React from "react";
interface Props {
  Title: string;
}
export const CHeading = (props: Props) => {
  return (
    <>
       <div className="col-md-12">
        <h4  style={{ margin: "5px",fontWeight:"bold" }}>
          {props.Title}
        </h4>
      </div>
      <hr />
    </>
  );
};
