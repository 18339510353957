import Swal from "sweetalert2";
const SwalAlert = (props: any) => {
  
  if (props.code === "ERR_NETWORK") {
    return Swal.fire({
      timer: 2500,
      icon: "error",
      title: "Oops2...",
      text: "Network Error !",
    });
  } else if (props.code === "ERR_BAD_REQUEST") {
    return Swal.fire({
      timer: 2500,
      icon: "error",
      title: "Oops1...",
      text: props.response.data,
    });
  }
};

export default SwalAlert;
