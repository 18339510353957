import React, { useCallback, useEffect, useMemo, useState } from "react";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import { Button } from "react-bootstrap";
import UserModel from "../Models/UserModel";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { CHeading } from "../../CHeading";
import SwalAlert from "../../common/SwalAlert";
import Loading from "../../common/Loading";
interface Iuser {
  UserID: number;
  CompanyID: number;
  CompanyName: string;
  FirstName: string;
  LastName: string;
  LoginId: string;
  MobileNo: string;
  Password: string;
  Active: boolean;
  EmailID: string;
  RoleId: number;
}


const UserPrivileges = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setuserData] = useState<Iuser[]>([]);
  const [editUserData, seteditUserData] = useState<Iuser[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [show, setShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    async function populateUsers() {
      setLoading(true);
      await getApi("/api/Users/getAllUserPrivilege")
        .then((response) => {
          
          setuserData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error.code);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateUsers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  const columnDefs = [
    {
      headerName: "First Name",
      field: "FirstName",
      filter: true,
    },
    {
      headerName: "Last Name",
      field: "LastName",
      filter: true,
    },
    {
      headerName: "Company Name",
      field: "CompanyName",
      filter: true,
    },
    {
      headerName: "Login Id",
      field: "LoginId",
      filter: true,
    },
    // {
    //   headerName: "Mobile No",
    //   field: "MobileNo",
    //   filter: true,
    //   resizable: true,
    // },
    {
      headerName: "Email Id",
      field: "EmailId",
      filter: true,
    },
    {
      headerName: "Role",
      field: "RoleName",
      filter: true,
    },
    {
      headerName: "Permissions",
      field: "PermissionName",
      filter: true,
      flex:1
    }
  ];

  function resetState() {
    const newItems: any = {
      UserID: 0,
      FirstName: "",
      LastName: "",
      LoginID: "",
      MobileNo: "",
      CompanyID: 0,
      Password: "",
      EmailID: "",
      Active: true,
      RoleId: 0,
    };
    seteditUserData(newItems);
  }

  const OpenModel = (obj: any) => {
    
    if (typeof obj === "undefined") {
      resetState();
      handleShow();
      setIsUpdate(false);
      setTitle("Add User");
    } else {
      seteditUserData(obj.data);
      setIsUpdate(true);
      handleShow();
      setTitle("Update User");
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
    };
  }, []);

  const Columns = [
    "FirstName",
    "LastName",
    "LoginID",
    // "MobileNo",
    "CompanyID",
    "Password",
    "EmailID"
    
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title="User Privilages" />
        <div className="container-fluid">
          {userData && (
            <CAgGrid
              Columns={Columns}
              rowData={userData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              OpenModel={OpenModel}
              isShowCreateButton={false}
              isShowExportButton={false}
              isShowQuickFilter={true}
              fileName="UserPrivileges"
            />
          )}
        </div>
      </div>
      {show && (
        <UserModel
          show={show}
          handleClose={handleClose}
          editData={editUserData}
          seteditUserData={seteditUserData}
          isUpdate={isUpdate}
          handleRefresh={handleRefresh}
          title={title}
        />
      )}
    </>
  );
};

export default UserPrivileges;
