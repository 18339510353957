import React, { useEffect, useState } from "react";
import Loading from "../../common/Loading";
import { CHeading } from "../../CHeading";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { getApi, postApi, putApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import Swal from "sweetalert2";

 const  CreateNewPassword=()=>{
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [UserList, setUserList] = useState([]);
    const [validated, setValidated] = useState(false);
    
    const iniEditData={
        ConfirmNewPassword:"",
        NewPassword:"",
        UserId:""
    };
    const [EditData, setEditData]=useState(iniEditData);

    const handleRefresh=()=>{
setEditData(iniEditData);
    }
  useEffect(() => {
    async function populateCompany() {
      await getApi("/api/Common/getAllUser")
        .then((response) => {
            setUserList(response.data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
   
    let ignore = false;
    if (!ignore) {
      populateCompany();
    }
    return () => {
      ignore = true;
    };
  }, []);
  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    
    event.preventDefault();

if(EditData.ConfirmNewPassword!==EditData.NewPassword){
    Swal.fire("New password and confirm new password should be same.", "", "info");
return false;

}

    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        timer: 2500,
        title: "Do you want to reset password?",
        icon:"info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Submit",
        // denyButtonText: `Don't Submit`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await putApi("/api/Users/CreateNewPassword", EditData)
            .then(async (response) => {
             
              Swal.fire(response.data, "", "success");
              handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
    setValidated(true);
  };

    return (
        <>
          {loading && <Loading />}
          <div className="container-fluid">
            <CHeading Title="Create New Password" />
            <div className="container-fluid">
            <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >

<Container>
  
      <Row>
        <Col md={{ span:8, offset: 4 }}> 
        <Form.Group as={Col} md="6" controlId="validationCustom02">
              <Form.Label>Users</Form.Label>
              <Form.Select
                required
                aria-label="Select User"
                onChange={(e) =>
                  setEditData({
                    ...EditData,
                    UserId: e.target.value,
                  })
                }
                value={EditData.UserId}
              >
                
                {UserList.map((data: any) => (
                  <option key={data.code} value={data.code}>
                    {data.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                required
                type="password"
                value={EditData.NewPassword}
                placeholder="New Password"
                onChange={(e) =>
                  setEditData({
                    ...EditData,
                    NewPassword: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                required
                type="password"
                value={EditData.ConfirmNewPassword}
                placeholder="Confirm New Password"
                onChange={(e) =>
                  setEditData({
                    ...EditData,
                    ConfirmNewPassword: e.target.value,
                  })
                }
              />
            
            </Form.Group>            
            </Col>
  
      </Row>
      <Row>
        <Col md={{ span:6, offset: 4 }}> <div className="mt-2 col-md-6" style={{float:"right"}}>
            <InputGroup>
            <Button size="sm" className="btn btn-primary" type="submit">
              Submit
            </Button>            
            <Button size="sm" className=" btn btn-danger"  type="button" onClick={handleRefresh} >
              Cancel
            </Button>
            </InputGroup>
            </div>
            </Col>
            </Row>
    </Container>

      

        
        </Form>
            </div>
          </div>
          
        </>
      );
}
export default CreateNewPassword;