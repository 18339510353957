import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

function LimitedFieldOfferModel(props: any) {
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-12">
                  <input type="hidden" value={props.editData?.OfferID} />
                  <div className="row">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                      <Form.Label>Employee Number:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeNumber || ""}
                        placeholder="Employee Number:"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeNumber: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                      <Form.Label>First Name:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeFirstName || ""}
                        placeholder="First Name"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeFirstName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom03">
                      <Form.Label>Last Name:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeLastName || ""}
                        placeholder="Last Name"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeLastName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                  </div>
                </div>

              </div>
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <Card>
                    <Card.Header>Details</Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <Form.Group as={Col} md="12" controlId="validationCustom17">
                          <Form.Label>Contract Schedule:</Form.Label>
                          <Form.Control
                           
                            required
                            type="text"
                            value={props.editData?.ODContract_ContractSchedule || ""}
                            placeholder="Schedule"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_ContractSchedule: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom18">
                          <Form.Label>Contract Schedule Full Description:</Form.Label>
                          <Form.Control
                            
                            required
                            type="text"
                            value={
                              props.editData
                                ?.ODContract_ContractScheduleFullDescription || ""
                            }
                            placeholder="Schedule Full Description"
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_ContractScheduleFullDescription:
                                  e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom21">
                          <Form.Label>Multiple Locations:</Form.Label>
                          <Form.Control
                            type="text"
                            value={
                              props.editData?.ODContract_MultipleLocations || ""
                            }
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_MultipleLocations:
                                  e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom21"
                        >
                          <Form.Label>Secondary Location:</Form.Label>
                          <Form.Control
                            type="text"
                            value={
                              props.editData?.ODContract_SecondaryLocation || ""
                            }
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_SecondaryLocation:
                                  e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom21"
                        >
                          <Form.Label>Employee Location:</Form.Label>
                          <Form.Control
                            type="text"
                            value={props.editData?.EmployeeLocation || ""}
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                EmployeeLocation: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom20"
                        >
                          <Form.Label>Termination Date:</Form.Label>
                          <Form.Control
                            type="Date"
                            value={props.editData?.TerminationDate}
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                TerminationDate: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom17"
                        >
                          <Form.Label>PLI Provision:</Form.Label>
                          <Form.Control
                            type="text"
                            value={props.editData?.ODContract_PLIProvision || ""}
                            onChange={(e) =>
                              props.seteditData({
                                ...props.editData,
                                ODContract_PLIProvision: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default LimitedFieldOfferModel;
