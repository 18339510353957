import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useCallback } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Accordion, Button } from "react-bootstrap";
import TableComponent from "../../common/TableComponent";
import { getApi, postApi } from "../../../AxiosRequest";
import { printDate } from "../../common/Util";
import SwalAlert from "../../common/SwalAlert";
import UploadModel from "./UploadModel";
import { encryptString } from "../../common/cryptoUtils";

function DocumentModalOffer(props: any) {
  console.log("🚀 ~ file: DocumentModalOffer.tsx:14 ~ DocumentModalOffer ~ props:", props)
  const [refresh, setrefresh] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [showOtherUpload, setshowOtherUpload] = useState(false);
  const [otherDocumentType, setOtherDocumentType] = useState([]);
  const columnHeaders: string[] = [
    "Document Type",
    "Added By",
    "Added On",
    "Download",
    "Delete",
  ];
  const handleOtherUploadShow = useCallback(() => {
    setshowOtherUpload(true);
  }, []);
  const handleOtherUploadClose = useCallback(() => {
    setshowOtherUpload(false);
  }, []);
  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };
  useEffect(() => {
    async function PopulateOtherDocument(companyID: any, OfferID: any) {
      if (companyID !== 0 && OfferID !== 0) {
        let param = {
          OfferID: OfferID || "",
          companyID: companyID || "",
        };
        await postApi("/api/UploadFile/ListOfferFilesfromStorage", null, param)
          .then((response) => {

            let data = response.data;

            for (let index = 0; index < data.length; index++) {
              data[index].CreatedDate = printDate(
                data[index].CreatedDate
              );
            }
            setRowData(data);

          })
          .catch((error) => {
            SwalAlert(error);
          });
      }
    }

    let IsCancel = false;
    if (!IsCancel) {     
      PopulateOtherDocument(props.editData.CompanyID, props.editData.OfferID);

    };
    return () => {
      IsCancel = true;
    };
  }, [refresh]);
  useEffect(() => {
    async function populateDocumentType() {
      await getApi("/api/Common/GetLookupList?name=OtherDocument")
        .then((response) => {
          let data = response.data;
          setOtherDocumentType(data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let IsCancel = false;
    if (!IsCancel) {
      populateDocumentType();
    }
    return () => {
      IsCancel = true;
    };
  }, []);
  return (
    <Modal
    size="xl"
      show={props.show}
      onHide={props.handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>

      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-10">
                  <input type="hidden" value={props.editData?.OfferID} />
                  {/* <div className="row">
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Form.Label>Employee Number</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeNumber || ""}
                        placeholder="Employee Number:"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeNumber: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom02">
                      <Form.Label>First Name:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeFirstName || ""}
                        placeholder="First Name"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeFirstName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom03">
                      <Form.Label>Last Name:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeLastName || ""}
                        placeholder="Last Name"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeLastName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                      <Form.Label>State:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.LocationState || ""}
                        placeholder="State"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            LocationState: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </div> */}
                </div>

              </div>
              
              <Button
                      size="sm"
                      variant="primary"
                      onClick={handleOtherUploadShow}
                      style={{ marginLeft: "15px",marginBottom:"15px" }}
                    >
                      Upload Other Document
                    </Button>
                    
              {showOtherUpload && (
                <UploadModel
                  showUpload={showOtherUpload}
                  companyId={encryptString(props.editData.CompanyID)}
                  handleRefresh={handleRefresh}
                  handleUploadClose={handleOtherUploadClose}
                  title="Upload Offer Documents"
                  UploadOfferDocument={true}
                  otherDocumentType={otherDocumentType}
                  offerId={encryptString(props.editData.OfferID)} 
                  InsProof={false}                
                />
              )}

                    {rowData && (
                      <TableComponent
                        rowData={rowData}
                        columnHeaders={columnHeaders}
                        tableHeight="30vh"
                        tableWidth="70vw"
                        handleRefresh={handleRefresh}
                        isDeleteCol={true}
                      />
                    )}
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default DocumentModalOffer;
