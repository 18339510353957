import React from "react";
import "../../../src/PageNotFound.css";
import Image404 from "../common/Image/404.svg";
import Imagemeteor from "../common/Image/meteor.svg";
import Imageastronaut from "../common/Image/astronaut.svg";
import Imagespaceship from "../common/Image/spaceship.svg";
import { useNavigate } from "react-router-dom";
function NotFound() {
  let navigate = useNavigate();
  let path = "/Dashboard";
  const handleBack = () => {
    navigate(path);
  };
  return (
    <>
      <div className="container-fluid bodynotfound">
        <div className="mars"></div>
        <img src={Image404} className="logo-404" />
        <img src={Imagemeteor} className="meteor" />
        <p className="title">Oh no!!</p>
        <p className="subtitle">
          You’re either misspelling the URL <br /> or requesting a page that's
          no longer here.
        </p>
        <div style={{ textAlign: "center" }}>
          <a className="btn-back" href="#" onClick={handleBack}>
            Back to previous page
          </a>
        </div>
        <img src={Imageastronaut} className="astronaut" />
        <img src={Imagespaceship} className="spaceship" />
      </div>
    </>
  );
}

export default NotFound;
