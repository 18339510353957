import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import CompanyModel from "../Models/CompanyModel";
import { Button } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Loading from "../../common/Loading";
interface ICompany {
  CompanyID: number;
  CompanyName: string;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Country: string;
  PhoneNo: string;
  EmailID: string;
  MobileNo: string;
  Active: boolean;
}
const CompanyDetails = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [editCompanyData, seteditCompanyData] = useState<ICompany[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [show, setShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const [companyData, setCompanyData] = useState<ICompany[]>([]);
  useEffect(() => {
    async function populateCompany() {
      setLoading(true);
      await getApi("/api/Company/getallCompanyDetails")
        .then((response) => {
          setCompanyData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateCompany();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  const columnDefs = [
    {
      field: "Action",
      minWidth: 150,
      cellRenderer: (data: any) => (
        <div>
          <Button
            size="sm"
            className="btn btn-info btnLink"
            onClick={() => OpenModel(data)}
          >
            <i>
              <FaEdit />
            </i>
          </Button>{" "}
          {/* <Button
            size="sm"
            className="btn btn-danger btnLink"
            onClick={() => alert(data.data.CompanyID)}
          >
            <i>
              <FaTrashAlt />
            </i>
          </Button> */}
        </div>
      ),
    },
    {
      headerName: "Company Id",
      field: "CompanyID",
      filter: true,
    },
    {
      headerName: "Company Name",
      field: "CompanyName",
      filter: true,
    },
    {
      headerName: "Address 1",
      field: "Address1",
      filter: true,
    },
    {
      headerName: "Address 2",
      field: "Address2",
      filter: true,
    },
    {
      headerName: "City",
      field: "City",
      filter: true,
    },
    {
      headerName: "State",
      field: "State",
      filter: true,
    },
    {
      headerName: "Country",
      field: "Country",
      filter: true,
    },
    {
      headerName: "Phone No",
      field: "PhoneNo",
      filter: true,
    },
    {
      headerName: "Email Id",
      field: "EmailID",
      filter: true,
    },
    {
      headerName: "Mobile No",
      field: "MobileNo",
      filter: true,
    },
    {
      headerName: "Active",
      field: "Active",
      filter: true,
    },
  ];
  function resetState() {
    const newItems: any = {
      CompanyID: 0,
      CompanyName: "",
      Address1: "",
      Address2: "",
      City: "",
      State: "",
      Country: "",
      PhoneNo: "",
      EmailID: "",
      MobileNo: "",
      Active: true,
    };
    seteditCompanyData(newItems);
  }

  const OpenModel = (obj: any) => {
    if (typeof obj === "undefined") {
      resetState();
      handleShow();
      setIsUpdate(false);
      setTitle("Add Company");
    } else {
      seteditCompanyData(obj.data);
      setIsUpdate(true);
      handleShow();
      setTitle("Update Company");
    }
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const Columns = [
    "CompanyName",
    "Address1",
    "Address2",
    "City",
    "State",
    "Country",
    "PhoneNo",
    "EmailID",
    "MobileNo",
    "Active",
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title="Company Details" />
        <div className="container-fluid">
          {companyData && (
            <CAgGrid
              Columns={Columns}
              rowData={companyData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              OpenModel={OpenModel}
              isShowCreateButton={false}
              isShowExportButton={false}
              isShowQuickFilter={true}
              fileName="Companies"
            />
          )}
        </div>
      </div>
      {show && (
        <CompanyModel
          show={show}
          handleClose={handleClose}
          editData={editCompanyData}
          seteditCompanyData={seteditCompanyData}
          isUpdate={isUpdate}
          handleRefresh={handleRefresh}
          title={title}
        />
      )}
    </>
  );
};

export default CompanyDetails;
