import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getApi, postApi, putApi } from "../../../AxiosRequest";
import Swal from "sweetalert2";
import SwalAlert from "../../common/SwalAlert";

interface ICompany {
  code: number;
  name: string;
}
interface IRoles {
  RoleId: number;
  RoleName: string;
}
function UserModel(props: any) {
  
  const [editCheck, setEditCheck] = useState(false);
  
  const [validated, setValidated] = useState(false);
  const [companyData, setCompanyData] = useState<ICompany[]>([]);
  // const [RoleData, setRoleData] = useState<IRoles[]>([]);
  useEffect(() => {
    async function populateCompany() {
      await getApi("/api/Company/getallCompany")
        .then((response) => {
          setCompanyData(response.data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    // async function populateRoles() {
    //   await getApi("/api/Common/getUserRoles")
    //     .then((response) => {
          
    //       setRoleData(response.data);
    //     })
    //     .catch((error) => {
    //       SwalAlert(error);
    //     });
    // }
    let ignore = false;
    if (!ignore) {
      populateCompany();
      // populateRoles();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      // Swal.fire({
      //   timer: 2500,
      //   title: "Do you want to save the changes?",
      //   showDenyButton: true,
      //   showCancelButton: true,
      //   confirmButtonText: "Save",
      //   denyButtonText: `Don't save`,
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
          await postApi("/api/Users/createUser", props.editData)
            .then(async (response) => {
              props.handleClose();
               props.handleClick();
               props.setMessage(response.data);
              // Swal.fire(response.data, "", "success");
              props.handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
      //   } else if (result.isDenied) {
      //     Swal.fire("Changes are not saved", "", "info");
      //   }
      // });
    }
    setValidated(true);
  };

  const handleUpdate = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        timer: 2500,
        title: "Do you want to update the changes?",
        icon:"info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Update",
        //denyButtonText: `Don't Update`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await putApi("/api/Users/editUser", props.editData)
            .then(async (response) => {
              props.handleClose();
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("Changes are not updated", "", "info");
        }
      });
    }
    setValidated(true);
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={props.isUpdate === true ? handleUpdate : handleSubmit}
        >
          <div className="container">
          
            <input type="hidden" value={props.editData.UserID} />
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label>First name</Form.Label>
              <Form.Control
                required
                type="text"
                value={props.editData.FirstName}
                placeholder="First name"
                
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    FirstName: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustom02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
                required
                type="text"
                value={props.editData.LastName}
                placeholder="Last name"
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    LastName: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustom03">
              <Form.Label>Login Id</Form.Label>
              <Form.Control
                required
                type="text"
                value={props.editData.LoginID}
                placeholder="Login Id"
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    LoginID: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label>Mobile No</Form.Label>
              <Form.Control
                required
                type="text"
                maxLength={12}
                value={props.editData.MobileNo}
                placeholder="Mobile Number"
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    MobileNo: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustom05">
              <Form.Label>Company</Form.Label>
              <Form.Select
                aria-label="Select Company"
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    CompanyID: Number(e.target.value),
                  })
                }
                value={props.editData.CompanyID}
              >
                {companyData.map((data: any) => (
                  <option key={data.code} value={data.code}>
                    {data.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            {/* <Form.Group as={Col} md="12" controlId="validationCustom08">
              <Form.Label>Role</Form.Label>
              <Form.Select
                aria-label="Select Role"
                required={true}
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    RoleId: e.target.value,
                  })
                }
                value={props.editData.RoleId}
              >
                <option >Select</option>
                {RoleData.map((data: any) => (
                  <option key={data.code} value={data.code}>
                    {data.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group> */}
            <Form.Group as={Col} md="12" controlId="validationCustom06" hidden={props.isUpdate}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="Password"
                value={props.editData.Password}
                placeholder="Password"
                
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    Password: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationCustom07">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="Email"
                value={props.editData.EmailID}
                placeholder="name@example.com"
                required={true}
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    EmailID: e.target.value,
                  })
                }
              />
              {/* <Form.Control.Feedback type="invalid">
            Please provide a valid city.
          </Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Check
                label="Active"
                checked={props.editData?.Active}
                onChange={(e) =>
                  props.seteditUserData({
                    ...props.editData,
                    Active: e.target.checked,
                  })
                }
              />
            </Form.Group>
<hr/>
            <div className="mt-2 btn-Model-margin">
              <Button size="sm" type="submit" className="btn-same">
                {props.isUpdate === true ? "Update" : "Save"}
              </Button>
             
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default UserModel;
