import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { getApi, postApi, putApi } from "../../../AxiosRequest";
import Swal from "sweetalert2";
import SwalAlert from "../../common/SwalAlert";
interface ICompany {
  code: number;
  name: string;
}

function EmailSettingModal(props: any) {
  
  const [validated, setValidated] = useState(false);
  const [companyData, setCompanyData] = useState<ICompany[]>([]);
  const [EmailGroupData, setEmailGroupData] = useState([]);
  useEffect(() => {
    async function populateCompany() {
      await getApi("/api/Common/getallCompany")
        .then((response) => {
          setCompanyData(response.data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    async function populateEmailGroups() {
      await getApi("/api/Common/GetEmailGroupsSegment")
        .then((response) => {
          setEmailGroupData(response.data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateCompany();
      populateEmailGroups();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        timer: 2500,
        title: "Do you want to save the changes?",
        icon:"info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Save",
        // denyButtonText: `Don't save`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await postApi("/api/common/AddOrUpdateGroupEmails", props.editData)
            .then(async (response) => {
              props.handleClose();
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
    setValidated(true);
  };

  const handleUpdate = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
      Swal.fire({
        timer: 2500,
        title: "Do you want to update the changes?",
        icon:"info",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Update",
        // denyButtonText: `Don't Update`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await putApi("/api/common/AddOrUpdateGroupEmails", props.editData)
            .then(async (response) => {
              props.handleClose();
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
        } else if (result.isDenied) {
          Swal.fire("Changes are not updated", "", "info");
        }
      });
    }
    setValidated(true);
  };

  return (
    <Modal show={props.show} onHide={props.handleClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={props.isUpdate === true ? handleUpdate : handleSubmit}
        >
          <div className="container">
            <input type="hidden" value={props.editData.ID} />
            <input type="hidden" value={props.editData.Segment} />
            <Form.Group as={Col} md="10" controlId="validationCustom01" >
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="Email"
                value={props.editData?.EmailId}
                placeholder="Email"
                onChange={(e) =>
                  props.setEmailSettingData({
                    ...props.editData,
                    EmailId: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group as={Col} md="10" controlId="validationCustom02">
              <Form.Label>CC Email</Form.Label>
         
              <Form.Control
              
                as="textarea" 
                value={props.editData?.ccEmailId}
                placeholder="cc Email"
                onChange={(e) =>
                  props.setEmailSettingData({
                    ...props.editData,
                    ccEmailId: e.target.value,
                  })
                }
              />
            </Form.Group>
           
            {/* <Form.Group as={Col} md="10" controlId="validationCustom04">
              <Form.Label>Email Group</Form.Label>
              <Form.Select
                required
                aria-label="Email Group"
                onChange={(e) =>
                  props.setEmailSettingData({
                    ...props.editData,
                    EmailGroup: e.target.value,
                  })
                }
                value={props.editData.EmailGroup}
              >
                <option >Select</option>
                {EmailGroupData.map((data: any) => (
                  <option key={data.code} value={data.code}>
                    {data.Name}
                  </option>
                ))}
              </Form.Select>
              
            </Form.Group>  */}
            <Form.Group as={Col} md="10" controlId="validationCustom05">
              <Form.Check
                label="Active"
                checked={props.editData?.Active}
                onChange={(e) =>
                  props.setEmailSettingData({
                    ...props.editData,
                    Active: e.target.checked,
                  })
                }
              />
            </Form.Group>
          </div>

          <div className="mt-2 btn-Model-margin">
            <Button size="sm" type="submit">
              {props.isUpdate === true ? "Update" : "Save"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EmailSettingModal;
