import React from "react";
import { FaCopyright } from "react-icons/fa";

const CFooter = () => {
  return (
    <footer className="footer">
      <div className="footer_p">
        <i>
          <FaCopyright />
        </i>
        {" "}
        2023 Product. All right reserved.
      </div>
    </footer>
  );
};

export default CFooter;
