import { useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { Button, InputGroup } from "react-bootstrap";
import { FaEdit, FaFileExport } from "react-icons/fa";
function CAgGrid(props: {
  rowData: any[] | null;
  columnDefs: (ColDef<any, any> | ColGroupDef<any>)[] | null | undefined;
  defaultColDef: ColDef<any, any> | undefined;
  OpenModel?: any | undefined;
  isShowCreateButton?: boolean | false;
  isShowExportButton?: boolean | false;
  rowSelectionType?:any;
  fileName?: string;
  Columns?: any[];
  isShowQuickFilter?: boolean | false;
  Gridheight?: any;
  Gridwidth?: any;
  onSelectionChanged?:any;

}) {
  const gridRef = useRef<AgGridReact>(null);
  const downloadCSV = () => {
    if (props.rowData !== null && props?.rowData.length > 0) {
      const excelParams = {
        columnSeparator: ",",
        columnKeys: props.Columns,
        allColumns: false,
        fileName: props.fileName + ".csv",
        skipHeader: false,
        prependContent: props.fileName + " List\n",
        skipFooters: false,
        suppressQuotes: false,
        onlySelected: false,
        appendContent:
          "\n \n Total number of " +
          props.fileName +
          " :" +
          gridRef.current?.api.getModel().getRowCount() +
          " \n",
      };
      gridRef.current?.api.exportDataAsCsv(excelParams);
    }
  };

  const onFilterTextBoxChanged = useCallback((val: any) => {
    gridRef?.current?.api.setQuickFilter(val.target.value);
  }, []);

  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-3 d-flex">
                <InputGroup>
                  <Button
                    style={{
                      display:
                        props.isShowCreateButton === false ||
                        props.isShowCreateButton === undefined
                          ? "none"
                          : "flex",
                    }}
                    size="sm"
                    className="btn btn btn-primary"
                    onClick={() => props.OpenModel()}
                  >
                    <FaEdit style={{ margin: "4px 0px 0px 0px" }} /> &nbsp;
                    Create
                  </Button>
                  <input
                    style={{
                      display:
                        props.isShowQuickFilter === false ||
                        props.isShowQuickFilter === undefined
                          ? "none"
                          : "flex",
                      height: "32px",
                    }}
                    className="form-control"
                    type="text"
                    id="filter-text-box"
                    placeholder="Quick Filter..."
                    onInput={onFilterTextBoxChanged}
                  />
                </InputGroup>
              </div>
              <div className="col-md-9 d-flex justify-content-end">
                <div className="form-group">
                  <InputGroup>
                    <Button
                      style={{
                        display:
                          props.isShowExportButton === false ||
                          props.isShowExportButton === undefined
                            ? "none"
                            : "flex",
                      }}
                      size="sm"
                      className="btn btn-warning"
                      onClick={downloadCSV}
                    >
                      <FaFileExport style={{ margin: "4px 0px 0px 0px" }} />
                      &nbsp; Export
                    </Button>
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="ag-theme-alpine"
          style={{marginTop:"2px",
            height: props.Gridheight !== undefined ? props.Gridheight : "75vh",
            width: props.Gridwidth !== undefined ? props.Gridwidth : "auto",
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={props.rowData}
            columnDefs={props.columnDefs}
            defaultColDef={props.defaultColDef}
            animateRows={true}
            rowSelection={props.rowSelectionType}
            suppressAutoSize={true}
            pagination={true}
            onSelectionChanged={props.onSelectionChanged}
           
            paginationPageSize={22}
            headerHeight={30}
            rowHeight={30}
          />
        </div>
      </div>
    </>
  );
}

export default CAgGrid;
