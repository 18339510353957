import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import swalAlert from "../../common/SwalAlert";
import OfferModel from "../Models/OfferModel";
import { Button, InputGroup } from "react-bootstrap";
import { FaEdit, FaInfoCircle, FaTrashAlt } from "react-icons/fa";
import { printDate } from "../../common/Util";
import Loading from "../../common/Loading";
import { useNavigate } from "react-router-dom";
import { encryptString } from "../../common/cryptoUtils";


interface IOffers {
  OfferDate: Date;
  CompanyName: string;
  LocationState: string;
  OfferID: number;
  CompanyID: number;
  ContractID: number;
  EmployeeNumber: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  EmployeeLocation: string;
  BaseSalary: number;
  DailyRate: number;
  ODContract_ProductionPercentage: number;
  ODContract_EnrolledInHealthBenefits: string;
  ODContract_PTODays: number;
  ODContract_PaidCEDays: number;
  ODContract_LegalEntity: string;
  ODContract_AgreementType: string;
  OriginalHireDate: Date;
  HireDate: Date;
  ODContract_RenewalDate: Date;
  ODContract_AutoRenew: boolean;
  ODContract_ContractSchedule: string;
  ODContract_ContractScheduleFullDescription: string;
  ODContract_ProductionThreshold: number;
  ODContract_ProductionPaymentFrequency: string;
  ODContract_AOADuesPercentage: number;
  ODContract_StateDuesPercentage: number;
  ODContract_LicensureDues: number;
  ODContract_MultipleLocations: string;
  ODContract_SecondaryLocation: string;
  Region: string;
  EmployeeGender: string;
  EmployeeBirthDate: Date;
  SeniorityDate: Date;
  TerminationDate: Date;
  Job: string;
  PayType: string;
  PayClass: string;
  ODContract_TuitionReimbursement: number;
  ODContract_CarTravelAllowanceMonthly: number;
  ODContract_SignOnBonus: number;
  ODContract_CEReimbursement: number;
  ODContract_PaidHolidays: number;
  ODContract_PLIProvision: boolean;
}

const AcceptedOffersAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [PageTitle, setPageTitle] = useState("");
  const [offerData, setofferData] = useState<IOffers[]>([]);
  const urlElementsAction = window.location.href.split("/")[3];
  useEffect(() => {
    async function populateOffers() {
      setLoading(true);

      let url = "";
      switch (urlElementsAction) {       
          case "AcceptedOffersEdit":
            url = "/api/Offers/getAllODOffers?type=0";
            setPageTitle("Offers");
            break;             
      }
      await getApi(url)
        .then((response) => {          
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
            data[index].CredStartDate = printDate(data[index].CredStartDate);
            data[index].CredCompletionDate = printDate(
              data[index].CredCompletionDate
            );
          }
          setofferData(data);
          setLoading(false);
        })
        .catch((error) => {
          swalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh,urlElementsAction]);

 
  let navigate = useNavigate();
  const OpenOfferDetailView = (obj: any) => {   
    let paramOfferID = encryptString(obj?.data?.OfferID);
       let paramCompanyID = encryptString(obj?.data?.CompanyID);
       let paramEdit = encryptString("yes");
    let path =
      "/OfferDetails/" +
      paramOfferID +
          "/" +
      paramCompanyID+
      "/" +
      paramEdit;
    navigate(path);
  };

  const columnDefs = [
    {
      headerName: "Employee #",
      field: "EmployeeNumber",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      maxWidth: 120,
    },
     {
      headerName: "Address",
      field: "EmployeeLocation",
      filter: true,
    },
    {
      field: "View/Edit",
      maxWidth: 120,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
           onClick={() => OpenOfferDetailView(data)}
          >
            <i
              title={
                "View Details for " +
                data.data.EmployeeFirstName +
                " " +
                data.data.EmployeeLastName
              }
            >
              <FaInfoCircle />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "Credentialing Status",
      field: "CredStatus",
      filter: true
    },
    {
      headerName: "Start Date",
      field: "CredStartDate",
      filter: true,
      maxWidth: 120,
    },
    {
      headerName: "Completion Date",
      field: "CredCompletionDate",
      filter: true,
      maxWidth: 160,
    },
    {
      headerName: "Contract Status",
      field: "ContractStatus",
      filter: true,
    },
  ];


  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const Columns = [
    "EmployeeNumber",    
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    "EmployeeLocation",
    // "LocationState",//
    "CredStatus",
    "CredStartDate",
    "CredCompletionDate",
    "ContractStatus"
  ];

 
  const OpenOfferEntry = (obj: any) => {    
    let path =
      "/CreateOffer" ;
    navigate(path);
  };
  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title={PageTitle} />
    
        <div className="container-fluid">
        <div className="row">
        <div style={{  marginTop: "5px" }}>
                <InputGroup >
                  <Button
                    type="submit"
                    className="mb-2 btn btn-sm"
                    size="sm"
                    variant="primary"
                    onClick={OpenOfferEntry}
                  >
                    Add Entry
                  </Button>  
                </InputGroup>
              </div>
        </div>
          {offerData && (
            <CAgGrid
              Columns={Columns}
              rowData={offerData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}              
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="AcceptedOffers"
            />
          )}
        </div>
      </div>
     
    </>
  );
};

export default AcceptedOffersAdmin;
