import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi, postApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import { Button } from "react-bootstrap";
import { FaEdit, FaFile, FaInfoCircle, FaTrashAlt } from "react-icons/fa";
import Loading from "../../common/Loading";
import Toast from "../../common/Toast";
import {
  checkDateIsNull,
  printDate,
  printDateMonthName,
} from "../../common/Util";
import Swal from "sweetalert2";
import { GDSContextType } from "../../common/@types.usr";
import AuthContext from "../../common/AuthProvider";
import LimitedFieldOfferModel from "../Models/LimitedFieldOfferModel";
import UploadModelOffer from "../Models/DocumentModalOffer";

interface IOffers {
  OfferDate: Date;
  CompanyName: string;
  LocationState: string;
  OfferID: number;
  CompanyID: number;
  ContractID: number;
  EmployeeNumber: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  EmployeeLocation: string;
  BaseSalary: number;
  DailyRate: number;
  ODContract_ProductionPercentage: number;
  ODContract_EnrolledInHealthBenefits: string;
  ODContract_PTODays: number;
  ODContract_PaidCEDays: number;
  ODContract_LegalEntity: string;
  ODContract_AgreementType: string;
  OriginalHireDate: Date;
  HireDate: Date;
  ODContract_RenewalDate: Date;
  ODContract_AutoRenew: boolean;
  ODContract_ContractSchedule: string;
  ODContract_ContractScheduleFullDescription: string;
  ODContract_ProductionThreshold: number;
  ODContract_ProductionPaymentFrequency: string;
  ODContract_AOADuesPercentage: number;
  ODContract_StateDuesPercentage: number;
  ODContract_LicensureDues: number;
  ODContract_MultipleLocations: string;
  ODContract_SecondaryLocation: string;
  Region: string;
  EmployeeGender: string;
  EmployeeBirthDate: Date;
  SeniorityDate: Date;
  TerminationDate: Date;
  Job: string;
  PayType: string;
  PayClass: string;
  ODContract_TuitionReimbursement: number;
  ODContract_CarTravelAllowanceMonthly: number;
  ODContract_SignOnBonus: number;
  ODContract_CEReimbursement: number;
  ODContract_PaidHolidays: number;
  ODContract_PLIProvision: boolean;
}

const CredentialingCompleted = () => {
  const { auth } = useContext(AuthContext) as GDSContextType;
  const [loading, setloading] = useState(false);
  const [credentialingData, setCredentialingData] = useState<IOffers[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [message, setMessage] = useState("");
  const [toastKey, setToastKey] = useState<number>(0);
  const [DocShow, setDocShow] = useState(false);

  //model open functionality
  const [show, setShow] = useState(false);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);
  const [title, setTitle] = useState("");
  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const OpenModel = (obj: any) => {
    if (typeof obj === "undefined") {
      handleShow();
      setTitle("Create Offers");
    } else {

      seteditOfferData(obj.data);
      handleShow();
      setTitle("View Offer Details");
    }
  };
  const [editOfferData, seteditOfferData] = useState<IOffers[]>([]);
  //
  /* Doc Modal Region */
  const handleDocShow = useCallback(() => {
    setDocShow(true);
  }, []);
  const handleDocClose = useCallback(() => {
    setDocShow(false);
  }, []);
  const [DocTitle, setDocTitle] = useState("");
  const OpenDocModel = (obj: any) => {
    if (typeof obj === "undefined") {
    } else {
      seteditOfferData(obj.data);
      handleDocShow();
      setDocTitle("Documents");
    }
  };
  /* Doc Modal Region */
  useEffect(() => {
    async function populateOffers() {
      setloading(true);
      await getApi("/api/Credentialing/getAllFullPSVGoToComplete")
        .then((response) => {
          let data = response.data;
          for (let index = 0; index < data.length; index++) {
            data[index].HireDate = printDate(data[index].HireDate);
            data[index].CredStartDate = printDate(data[index].CredStartDate);
            data[index].CredCompletionDate = printDate(
              data[index].CredCompletionDate
            );
          }
          setCredentialingData(data);
          setloading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateOffers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  const columnDefs = [
    {
      headerName: "Employee #",
      field: "EmployeeNumber",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "First Name",
      field: "EmployeeFirstName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Last Name",
      field: "EmployeeLastName",
      filter: true,
      maxWidth: 150,
    },
    {
      headerName: "Offer Date",
      field: "HireDate",
      filter: true,
      maxWidth: 120,
    },
    // {
    //   headerName: "Base Rate",
    //   field: "DailyRate",
    //   filter: true,
    //   maxWidth: 120,
    // },
    {
      headerName: "Address",
      field: "EmployeeLocation",
      filter: true,
    },
    {
      headerName: "State",
      field: "LocationState",
      filter: true,
      maxWidth: 90,
    },
    {
      field: "View Details",
      maxWidth: 120,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            // onClick={() => OpenOfferDetailView(data)}
            onClick={() => OpenModel(data)}
          >
            <i
              title={
                "View Details for " +
                data.data.EmployeeFirstName +
                " " +
                data.data.EmployeeLastName
              }
            >
              <FaInfoCircle />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      field: "Documents ",
      maxWidth: 120,
      resizable: false,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
            onClick={() => OpenDocModel(data)}
          >
            <i>
              <FaFile />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "Credentialing Status",
      field: "CredStatus",
      filter: true,
      maxWidth: 200,
    },
    {
      field: "Action",
      maxWidth: 310,
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "2px" }}
            size="sm"
            className={data.data?.CredStatusId === "4"
              ? "btn btn-primary btnLinkOther"
              : "btn btn-success btnLinkOther"}
            onClick={(e) =>
              UpdateOfferStatus(
                data.data?.CredStatusId === "4" ? "5" : "6",
                e,
                data
              )
            }
          >
            {data.data?.CredStatusId === "4"
              ? "Full Credentialing Start"
              : "Full Credentialing Complete"}
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "Start Date",
      field: "CredStartDate",
      filter: true,
      maxWidth: 120,
    },
    {
      headerName: "PSV Completion Date",
      field: "CredCompletionDate",
      filter: true,
      maxWidth: 200,
    },

  ];

  const UpdateOfferStatus = async (
    statusval: string,
    event: {
      currentTarget: any;
      preventDefault: () => void;
    },
    obj: any
  ) => {
    event.preventDefault();

    var msg = "";
    switch (statusval) {
      case "1":
        msg = "Do you want to start Primary Source Verification?";
        break;
      case "2":
        msg = "Do you want to complete Primary Source Verification?";
        break;
      case "5":
        msg = "Do you want to start Full Credentialing?";
        break;
      case "6":
        msg = "Do you want to complete Full Credentialing?";
        break;
    }

    Swal.fire({
      // timer: 2500,
      title: msg,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then(async (result: any) => {
      if (result.isConfirmed) {

        let parms = {
          offerId: obj.data?.OfferID,
          userId: auth?.Userdata?.UserId,
          status: statusval,
          employeeNumber: obj.data?.EmployeeNumber,
        };
        await postApi("/api/Credentialing/UpdateOffersStatus", null, parms)
          .then(async (response) => {
            handleRefresh();
            setMessage(response.data);
            handleClick();
          })
          .catch(async (error) => {
            SwalAlert(error);
          })
          .finally(async () => { });
      }
    });
  };


  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const Columns = [
    "EmployeeNumber",
    "EmployeeFirstName",
    "EmployeeLastName",
    "HireDate",
    "EmployeeLocation",
    "LocationState",
    "CredStatus",
    "CredStartDate",
    "CredCompletionDate",
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <Toast message={message} toastKey={toastKey} />
        <CHeading Title="Completed Credentialing" />
        <div className="container-fluid">
          {credentialingData && (
            <CAgGrid
              Columns={Columns}
              rowData={credentialingData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              isShowCreateButton={false}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="CompleteCredentialing"
            />
          )}
          {show && (
            <LimitedFieldOfferModel
              show={show}
              handleClose={handleClose}
              editData={editOfferData}
              handleRefresh={handleRefresh}
              title={title}
              isModelDisable={true}
              isButtonHideFromModel={true}
            />
          )}
          {DocShow && (
            <UploadModelOffer
              show={DocShow}
              handleClose={handleDocClose}
              editData={editOfferData}
              title={DocTitle}
              isModelDisable={true}
              isButtonHideFromModel={true}

            />)}
        </div>
      </div>

    </>
  );
};

export default CredentialingCompleted;
