import CryptoJS from "crypto-js";

const secretKey =
  "b89714f072712988269d978ba6fc08f1e2a4043c45f9206510632f78f8645924";

export const encryptString = (myString: any) => {
  const myval = myString.toString();
  const encryptedText = CryptoJS.AES.encrypt(myval, secretKey).toString();
  return encodeURIComponent(encryptedText);
};

export const generateRandomKey = () => {
  const keySizeBits = 256;
  const keySizeBytes = keySizeBits / 8;
  const randomBytes = CryptoJS.lib.WordArray.random(keySizeBytes);
  return randomBytes.toString(CryptoJS.enc.Hex);
};

export const decryptString = (encryptedText: any) => {
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(
      decodeURIComponent(encryptedText),
      secretKey
    );
    const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  } catch (error) {
    console.error("Error decrypting:", error);
    return null;
  }
};
