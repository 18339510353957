import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Accordion, Button } from "react-bootstrap";
import TableComponent from "../../common/TableComponent";
import { postApi } from "../../../AxiosRequest";
import { printDate } from "../../common/Util";
import SwalAlert from "../../common/SwalAlert";

function DocumentModal(props: any) {
  const [refresh, setrefresh] = useState(0);
  const [rowDataSigned, setRowDataSigned] = useState([]);
  const [rowData, setRowData] = useState([]);
  const columnHeaders: string[] = [
    "Document Type",
    "Added By",
    "Added On",
    "Download",
    "Delete",
  ];
  const columnHeadersSigned: string[] = [
    "Document Type",
    "Added By",
    "Added On",
    "Download"
    
  ];
  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  useEffect(() => {
    async function PopulateOtherDocument(companyID: any, contractID: any) {
      if (companyID !== 0 && contractID !== 0) {
        let param = {
          contractID: contractID || "",
          companyID: companyID || "",
        };
  
        await postApi("/api/UploadFile/ListOtherFilesfromStorage", null, param)
          .then((response) => {

            let data = response.data;
            for (let index = 0; index < data.length; index++) {
              data[index].CreatedDate = printDate(data[index].CreatedDate);
            }
            setRowData(data);
          })
          .catch((error) => {
            SwalAlert(error);
          });
      }
    }
    async function PopulateSignedDocumentFromStorage(
      companyID: any,
      contractID: any
    ) {
    
      if (companyID !== 0 && contractID !== 0) {
        let param = {
          contractID: contractID || "",
          companyID: companyID || "",
        };
        await postApi(
          "/api/UploadFile/ListSignedDocFilesfromStorage",
          null,
          param
        )
          .then((response) => {

            let data = response.data;
            for (let index = 0; index < data.length; index++) {
              data[index].CreatedDate = printDate(data[index].CreatedDate);
            }
            setRowDataSigned(data);
          })
          .catch((error) => {
            SwalAlert(error);
          });
      }
    }

    let IsCancel = false;
    if (!IsCancel) {
      let decryptedParamContractID = props.editData.ContractID;
      let decryptedParamCompanyID = props.editData.CompanyID;

   
      PopulateOtherDocument(decryptedParamCompanyID, decryptedParamContractID);
      PopulateSignedDocumentFromStorage(
        decryptedParamCompanyID,
        decryptedParamContractID
      );
    }
    return () => {
      IsCancel = true;
    };
  }, [refresh]);
  return (
    <Modal
      size="xl"
      show={props.show}
      onHide={props.handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="container">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-md-10">
                  <input type="hidden" value={props.editData?.OfferID} />
                  <div className="row">
                    <Form.Group as={Col} md="3" controlId="validationCustom01">
                      <Form.Label>Employee Number</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeNumber || ""}
                        placeholder="Employee Number:"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeNumber: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom02">
                      <Form.Label>First Name:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeFirstName || ""}
                        placeholder="First Name"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeFirstName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom03">
                      <Form.Label>Last Name:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.EmployeeLastName || ""}
                        placeholder="Last Name"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            EmployeeLastName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                      <Form.Label>State:</Form.Label>
                      <Form.Control
                        disabled={props?.isModelDisable}
                        required
                        type="text"
                        value={props.editData?.LocationState || ""}
                        placeholder="State"
                        onChange={(e) =>
                          props.seteditData({
                            ...props.editData,
                            LocationState: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                
              </div>
              <hr /> 

              <Accordion
                        defaultActiveKey={["3", "4"]}
                        alwaysOpen
                      >
                        
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            Download Signed Contract
                          </Accordion.Header>
                          <Accordion.Body>
                            {rowDataSigned && (
                              <TableComponent
                                rowData={rowDataSigned}
                                columnHeaders={columnHeadersSigned}
                                tableHeight="30vh"
                                tableWidth="60vw"
                                handleRefresh={handleRefresh}
                                isDeleteCol={true}
                                isHiddenCol={true}
                              />
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            Other Documents{" "}
                            
                          </Accordion.Header>
                          <Accordion.Body>
                            {rowData && (
                              <TableComponent
                                rowData={rowData}
                                columnHeaders={columnHeaders}
                                tableHeight="30vh"
                                tableWidth="60vw"
                                handleRefresh={handleRefresh}
                                isDeleteCol={true}

                              />
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>           
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default DocumentModal;
