export function getDatafromStorage(key: any) {
  return sessionStorage.getItem(key) !== null
    ? JSON.parse(sessionStorage.getItem(key) as any)
    : {};
}
export function setDatatoStorage(key: any, data: any) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function clearStorage() {
  sessionStorage.clear();
}

export function isLoggedIn() {
  let isLogin = true;
  if (
    sessionStorage.getItem("AccessToken") === null ||
    sessionStorage.getItem("AccessToken") === undefined
  )
    isLogin = false;
  else isLogin = true;
  return isLogin;
}

export function removeStorage(key: any) {
  sessionStorage.removeItem(key);
}
export function getUserIdFromLocalStorage(): number | null {
  const userDataString = sessionStorage.getItem("Userdata");

  if (userDataString) {
    try {
      const userData = JSON.parse(userDataString);
      if (userData && typeof userData.UserId === "number") {
        return userData.UserId;
      } else {
        console.error("Invalid user data structure or missing UserId");
      }
    } catch (error) {
      console.error("Error parsing user data:", error);
    }
  }

  return null;
}
export function getAccessToken() {
  return JSON.parse(sessionStorage.getItem("AccessToken") as string) || "";
}

export function getRefreshToken() {
  return JSON.parse(sessionStorage.getItem("RefreshToken") as string) || "";
}

export function forceLogout() {
  sessionStorage.clear();
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const checkDateIsNull = (params: Date) => {
  let isDateNull = false;
  let date = new Date(params);
  if (date.getFullYear() < 2000) isDateNull = true;
  else isDateNull = false;
  return isDateNull;
};

export const formatDate = (date: any) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return date.toLocaleDateString("en-US", options);
};

export const printDate = (params: Date) => {
  if (params !== null) {
    let date = new Date(params);
    return (
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "-" +
      date.getFullYear()
    );
  }
};

export const printDatewithAmPm = (dates: Date) => {
  if (dates === null) return "";
  const date = new Date(dates);
  const options: any = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const newDate = date.toLocaleString("en-US", options);
  return newDate.replace(/,/g, "");
};

export const printDate2 = (params: Date) => {
  if (params !== null) {
    let date = new Date(params);
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "-" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate())
    );
  }
};

export const printDateMonthName = (params: Date) => {
  let date = new Date(params);

  return (
    months[date.getMonth()] +
    "-" +
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "-" +
    date.getFullYear()
  );
};

export const SubsDates = (params1: Date,params2: Date) => {
  let date1 = new Date(params1).getFullYear();let date2 = new Date(params2).getFullYear();
  return date1-date2
};
