import { useEffect, useState } from "react";
import { CHeading } from "../../CHeading";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { getApi, postApi } from "../../../AxiosRequest";
import Swal from "sweetalert2";
import SwalAlert from "../../common/SwalAlert";
import Toast from "../../common/Toast";
import { useParams, useNavigate } from "react-router-dom";
import { decryptString } from "../../common/cryptoUtils";
import { printDate2 } from "../../common/Util";
type typeParams = {
  paramOfferID: any;
  paramContractID: any;
  paramCompanyID: any;
};
type IOffers = {
  LocationState: string;
  OfferID: any | number;
  CompanyID: any | number;
  EmployeeNumber: string;
  EmployeeLastName: string;
  EmployeeFirstName: string;
  EmployeeLocation: string;
  BaseSalary: any | number;
  DailyRate: any | number;
  ODContract_ProductionPercentage: any | number;
  ODContract_EnrolledInHealthBenefits: string;
  ODContract_PTODays: any | number;
  ODContract_PaidCEDays: any | number;
  ODContract_LegalEntity: string;
  ODContract_AgreementType: string;
  OriginalHireDate: Date | any;
  HireDate: Date | any;
  ODContract_RenewalDate: Date | any;
  ODContract_AutoRenew: boolean;
  ODContract_ContractSchedule: string;
  ODContract_ContractScheduleFullDescription: string;
  ODContract_ProductionThreshold: any | number;
  ODContract_ProductionPaymentFrequency: string;
  ODContract_AOADuesPercentage: any | number;
  ODContract_StateDuesPercentage: any | number;
  ODContract_LicensureDues: any | number;
  ODContract_MultipleLocations: string;
  ODContract_SecondaryLocation: string;
  Region: string;
  EmployeeGender: string;
  EmployeeBirthDate: Date | any;
  SeniorityDate: Date | any;
  TerminationDate: Date | any;
  Job: string;
  PayType: string;
  PayClass: string;
  ODContract_TuitionReimbursement: any | number;
  ODContract_CarTravelAllowanceMonthly: any | number;
  ODContract_SignOnBonus: any | number;
  ODContract_CEReimbursement: any | number;
  ODContract_PaidHolidays: any | string;
  ODContract_PLIProvision: string;
  CreatedBy: string;
  CreatedDate: Date | any;
  UpdatedDate: Date | any;
  EffectiveDate: Date | any;
  InitialTerm: string;
  PersonalEmail: string;
  BusinessEmail: string;
  Notes:string;
};
const OfferEntry = (props: any) => {
  const [companyData, setCompanyData] = useState([]);

  const initialState = {
    LocationState: "",
    OfferID: 0,
    CompanyID: 0,
    EmployeeNumber: "",
    EmployeeLastName: "",
    EmployeeFirstName: "",
    EmployeeLocation: "",
    BaseSalary: null,
    DailyRate: null,
    ODContract_ProductionPercentage: null,
    ODContract_EnrolledInHealthBenefits: "",
    ODContract_PTODays: null,
    ODContract_PaidCEDays: null,
    ODContract_LegalEntity: "",
    ODContract_AgreementType: "",
    OriginalHireDate: printDate2(new Date()),
    HireDate: printDate2(new Date()),
    ODContract_RenewalDate: printDate2(new Date()),
    ODContract_AutoRenew: false,
    ODContract_ContractSchedule: "",
    ODContract_ContractScheduleFullDescription: "",
    ODContract_ProductionThreshold: null,
    ODContract_ProductionPaymentFrequency: "",
    ODContract_AOADuesPercentage: null,
    ODContract_StateDuesPercentage: null,
    ODContract_LicensureDues: null,
    ODContract_MultipleLocations: "",
    ODContract_SecondaryLocation: "",
    Region: "",
    EmployeeGender: "",
    EmployeeBirthDate: printDate2(new Date()),
    SeniorityDate: printDate2(new Date()),
    TerminationDate: null,
    Job: "",
    PayType: "",
    PayClass: "",
    ODContract_TuitionReimbursement: null,
    ODContract_CarTravelAllowanceMonthly: null,
    ODContract_SignOnBonus: null,
    ODContract_CEReimbursement: null,
    ODContract_PaidHolidays: "",
    ODContract_PLIProvision: "",
    CreatedBy: "",
    CreatedDate: printDate2(new Date()),
    UpdatedBy: "",
    UpdatedDate: printDate2(new Date()),
    EffectiveDate: null,
    InitialTerm: "",
    PersonalEmail: "",
    BusinessEmail: "",
    Notes:""
  };
  const { paramOfferID, paramContractID, paramCompanyID } =
    useParams<typeParams>();
  const [OfferData, setOfferData] = useState<IOffers>(initialState);
  const [message, setMessage] = useState("");
  useEffect(() => {
    async function populateCompany() {
      await getApi("/api/Company/getallCompany")
        .then((response) => {
          setCompanyData(response.data);
        })
        .catch((error) => { });
    }
    let ignore = false;
    if (!ignore) {
      populateCompany();
    }
    return () => {
      ignore = true;
    };
  }, []);
  const [toastKey, setToastKey] = useState<number>(0);
  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };

  const handleReset = async (event: {
    currentTarget: any;
    preventDefault: () => void;
  }) => {
    event.preventDefault();
    ResetDataFunction();
  };

  let naviagate = useNavigate();

  const ResetDataFunction = () => {
    setOfferData(initialState);
    let path = "/CreateOffer";
    naviagate(path);
  };
  useEffect(() => {
    async function populateOffers(id: any) {
      await getApi("/api/Contracts/getOffersById?Id=" + id)
        .then((response) => {
          let data = response.data;

          if (data !== null) {
            data.EmployeeBirthDate = printDate2(data.EmployeeBirthDate);
            data.HireDate = printDate2(data.HireDate);
            data.OriginalHireDate = printDate2(data.OriginalHireDate);
            data.ODContract_RenewalDate = printDate2(
              data.ODContract_RenewalDate
            );
            data.SeniorityDate = printDate2(data.SeniorityDate);
            data.TerminationDate = printDate2(data.TerminationDate);
            data.EffectiveDate = printDate2(data.EffectiveDate);
          }
          setOfferData(data);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }

    let IsCancel = false;
    if (!IsCancel) {
      if (
        paramOfferID !== undefined &&
        paramOfferID !== 0 &&
        paramOfferID !== null
      ) {
        let decryptedParamOfferID = decryptString(paramOfferID);
        populateOffers(decryptedParamOfferID);
      } else {
        ResetDataFunction();
      }
    }
    return () => {
      IsCancel = true;
    };
  }, [paramOfferID]);

  const param = {
    isRenewWal: props.paramRenewal === undefined ? false : props.paramRenewal,
  };
  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
  }) => {
    event.preventDefault();

    Swal.fire({
      timer: 25000,
      title: "Do you want to save the changes?",
      icon: "info",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      // denyButtonText: `Don't save`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await postApi("/api/Offers/SaveRecords", OfferData, param)
          .then(async (response) => {
            //Swal.fire(response.data, "", "success");
            handleClick();
            setMessage(response.data);
            if (props.paramRenewal !== undefined) {
              alert("Record Updated Successfully.");
              props?.closeModal();
              props?.handleRefreshOfferEntry();
            } else {
              ResetDataFunction();
            }
          })
          .catch(async (error) => {
            debugger;
            let strError = "";
            if (error.response) {
              var comp = error.response.data.errors;
              if (comp == undefined) {
                strError = `Server Error: ${error.response.status} -${error.response.data}`;
              } else {

                strError = `Server Error: ${error.response.status} - ${JSON.stringify(error.response?.data?.errors)}`;
              }
            } else if (error.request) {
              strError = "No response received from the server";
            } else {
              strError = `Error: ${error.message}`;
            }
            Swal.fire(strError, "", "error");
          })
          .finally(async () => { });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved.", "", "info");
      }
    });
  };

  return (
    <>
      <Toast message={message} toastKey={toastKey} />
      <div className="container-fluid">
        <CHeading
          Title={props.newTitle === undefined ? "Offer Entry" : props.newTitle}
        />
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <div className="container-fluid">
            <div className="col-lg-12">
              <div className="col-md-12">
                <input type="hidden" value={OfferData?.OfferID} />
                <div className="row">
                  <Form.Group as={Col} md="2" controlId="validationCustom01">
                    <Form.Label className="required">
                      Employee #:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={OfferData?.EmployeeNumber || ""}
                      placeholder="Employee #"
                      onChange={(e) =>
                        setOfferData({
                          ...OfferData,
                          EmployeeNumber: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom02">
                    <Form.Label className="required">First Name:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={OfferData?.EmployeeFirstName || ""}
                      placeholder="First Name"
                      onChange={(e) =>
                        setOfferData({
                          ...OfferData,
                          EmployeeFirstName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="2" controlId="validationCustom03">
                    <Form.Label className="required">Last Name:</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      value={OfferData?.EmployeeLastName || ""}
                      placeholder="Last Name"
                      onChange={(e) =>
                        setOfferData({
                          ...OfferData,
                          EmployeeLastName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="1" controlId="validationCustom04">
                    <Form.Label>State:</Form.Label>
                    <Form.Control
                      type="text"
                      value={OfferData?.LocationState || ""}
                      onChange={(e) =>
                        setOfferData({
                          ...OfferData,
                          LocationState: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="1" controlId="validationCustom05">
                    <Form.Label className="required">Company:</Form.Label>
                    <Form.Select
                      required
                      value={OfferData?.CompanyID}
                      onChange={(e) =>
                        setOfferData({
                          ...OfferData,
                          CompanyID: e.target.value,
                        })
                      }
                    >
                      {companyData.map((item: any, index) => (
                        <option value={item.code} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group as={Col} md="2" controlId="validationCustom01">
                    <Form.Label>Employee Birth Date</Form.Label>
                    <Form.Control
                      value={OfferData?.EmployeeBirthDate || ""}
                      onChange={(e) =>
                        setOfferData({
                          ...OfferData,
                          EmployeeBirthDate: e.target.value,
                        })
                      }
                      type="date"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="1" controlId="validationCustom05">
                    <Form.Label className="required">Gender:</Form.Label>
                    <Form.Select
                      required
                      value={OfferData?.EmployeeGender}
                      onChange={(e) =>
                        setOfferData({
                          ...OfferData,
                          EmployeeGender: e.target.value,
                        })
                      }
                    >
                      <option value="" key={1}>
                        Select
                      </option>
                      <option value="M" key={1}>
                        Male
                      </option>
                      <option value="F" key={1}>
                        Female
                      </option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group
                    style={{ marginTop: "31px" }}
                    as={Col}
                    md="1"
                    controlId="validationCustom05"
                  >
                    <Form.Check
                      label="Auto Renew"
                      checked={OfferData?.ODContract_AutoRenew}
                      onChange={(e) =>
                        setOfferData({
                          ...OfferData,
                          ODContract_AutoRenew: e.target.checked,
                        })
                      }
                    ></Form.Check>
                  </Form.Group>
                </div>
              </div>{" "}
              <hr />
              <div className="row">
                <div className="col-md-12">
                  <Card>
                    <Card.Body style={{ height: "55vh", overflowY: "auto" }}>
                      <Card.Text>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom99"
                              >
                                <Form.Label>Daily Rate:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={OfferData?.DailyRate || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      DailyRate: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom06"
                              >
                                <Form.Label>Base Salary:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={OfferData?.BaseSalary || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      BaseSalary: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom07"
                            >
                              <Form.Label>Production Bonus %:</Form.Label>
                              <Form.Control
                                type="number"                              
                                value={
                                  OfferData?.ODContract_ProductionPercentage ||
                                  ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_ProductionPercentage:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom08"
                            >
                              <Form.Label>Production Threshold:</Form.Label>
                              <Form.Control
                                type="number"
                                value={
                                  OfferData?.ODContract_ProductionThreshold ||
                                  ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_ProductionThreshold:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom09"
                            >
                              <Form.Label>
                                Production Bonus Frequency:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={
                                  OfferData?.ODContract_ProductionPaymentFrequency ||
                                  ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_ProductionPaymentFrequency:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom09"
                            >
                              <Form.Label>Region:</Form.Label>
                              <Form.Control
                                type="text"
                                value={OfferData?.Region || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    Region: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom09"
                            >
                              <Form.Label>Job:</Form.Label>
                              <Form.Control
                                type="text"
                                value={OfferData?.Job || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    Job: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom09"
                              >
                                <Form.Label>Pay Type:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={OfferData?.PayType || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      PayType: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom09"
                              >
                                <Form.Label>Pay Class:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={OfferData?.PayClass || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      PayClass: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <Form.Group as={Col} md="12" controlId="validationCustom04">
                              <Form.Label>Notes:</Form.Label>
                              <Form.Control
                              as="textarea"
                              className="h50"
                                type="text"
                                maxLength={1000}
                                value={OfferData?.Notes || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    Notes: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-3">
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom12"
                              >
                                <Form.Label>PTO Days:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={OfferData?.ODContract_PTODays || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_PTODays: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom13"
                              >
                                <Form.Label>CE Days:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={OfferData?.ODContract_PaidCEDays || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_PaidCEDays: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom14"
                              >
                                <Form.Label>Association Dues %:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={
                                    OfferData?.ODContract_AOADuesPercentage || ""
                                  }
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_AOADuesPercentage:
                                        e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom15"
                              >
                                <Form.Label>State Dues %:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={
                                    OfferData?.ODContract_StateDuesPercentage ||
                                    ""
                                  }
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_StateDuesPercentage:
                                        e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom15"
                            >
                              <Form.Label>Licensures Fees:</Form.Label>
                              <Form.Control
                                type="number"
                                value={OfferData?.ODContract_LicensureDues || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_LicensureDues: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom15"
                            >
                              <Form.Label>Enrolled in Benefits:</Form.Label>
                              <Form.Select
                                value={
                                  OfferData?.ODContract_EnrolledInHealthBenefits ||
                                  ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_EnrolledInHealthBenefits:
                                      e.target.value,
                                  })
                                }
                              >
                                <option value="" key={1}>
                                  Select
                                </option>
                                <option value="Yes" key={1}>
                                  Yes
                                </option>
                                <option value="No" key={1}>
                                  No
                                </option>
                              </Form.Select>
                            </Form.Group>
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom15"
                              >
                                <Form.Label>Tuition Reimbursement:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={
                                    OfferData?.ODContract_TuitionReimbursement ||
                                    ""
                                  }
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_TuitionReimbursement:
                                        e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom17"
                              >
                                <Form.Label>CE Reimbursement:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={
                                    OfferData?.ODContract_CEReimbursement || ""
                                  }
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_CEReimbursement: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom15"
                            >
                              <Form.Label>
                                Car Travel Allowance Monthly:
                              </Form.Label>
                              <Form.Control
                                type="number"
                                value={
                                  OfferData?.ODContract_CarTravelAllowanceMonthly ||
                                  ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_CarTravelAllowanceMonthly:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom17"
                              >
                                <Form.Label>Paid Holidays:</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={OfferData?.ODContract_PaidHolidays || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_PaidHolidays: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom15"
                              >
                                <Form.Label>Sign On Bonus:</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={OfferData?.ODContract_SignOnBonus || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_SignOnBonus: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>

                            </div>
                          </div>
                          <div className="col-md-3">
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom16"
                            >
                              <Form.Label>Legal Entity:</Form.Label>
                              <Form.Control
                                type="text"
                                value={OfferData?.ODContract_LegalEntity || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_LegalEntity: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom17"
                            >
                              <Form.Label>Agreement Type:</Form.Label>
                              <Form.Control
                                type="text"
                                value={
                                  OfferData?.ODContract_AgreementType || ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_AgreementType: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom18"
                              >
                                <Form.Label>Original Hire Date:</Form.Label>
                                <Form.Control
                                  type="date"
                                  value={OfferData.OriginalHireDate || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      OriginalHireDate: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom19"
                              >
                                <Form.Label>Hire Date:</Form.Label>
                                <Form.Control
                                  type="date"
                                  value={OfferData?.HireDate || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      HireDate: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom20"
                              >
                                <Form.Label>Renewal Date:</Form.Label>
                                <Form.Control
                                  type="date"
                                  value={
                                    OfferData?.ODContract_RenewalDate || ""
                                  }
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      ODContract_RenewalDate: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom20"
                              >
                                <Form.Label>Seniority Date:</Form.Label>
                                <Form.Control
                                  type="date"
                                  value={OfferData?.SeniorityDate || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      SeniorityDate: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>


                            <div className="row">
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom09"
                              >
                                <Form.Label className="required">
                                  Effective Date:
                                </Form.Label>
                                <Form.Control
                                  value={OfferData?.EffectiveDate || ""}
                                  required
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      EffectiveDate: e.target.value,
                                    })
                                  }
                                  type="date"
                                />
                              </Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationCustom09"
                              >
                                <Form.Label className="required">
                                  Initial Term:
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  value={OfferData?.InitialTerm || ""}
                                  onChange={(e) =>
                                    setOfferData({
                                      ...OfferData,
                                      InitialTerm: e.target.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </div>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom21"
                            >
                              <Form.Label>Contract Schedule:</Form.Label>
                              <Form.Control
                                type="text"
                                value={
                                  OfferData?.ODContract_ContractSchedule || ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_ContractSchedule: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom21"
                            >
                              <Form.Label>
                                Contract Schedule Full Description:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={
                                  OfferData?.ODContract_ContractScheduleFullDescription ||
                                  ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_ContractScheduleFullDescription:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                          <div className="col-md-3">

                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom21"
                            >
                              <Form.Label>Multiple Locations:</Form.Label>
                              <Form.Control
                                type="text"
                                value={
                                  OfferData?.ODContract_MultipleLocations || ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_MultipleLocations:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom21"
                            >
                              <Form.Label>Secondary Location:</Form.Label>
                              <Form.Control
                                type="text"
                                value={
                                  OfferData?.ODContract_SecondaryLocation || ""
                                }
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_SecondaryLocation:
                                      e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom21"
                            >
                              <Form.Label>Employee Location:</Form.Label>
                              <Form.Control
                                type="text"
                                value={OfferData?.EmployeeLocation || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    EmployeeLocation: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom20"
                            >
                              <Form.Label>Termination Date:</Form.Label>
                              <Form.Control
                                type="Date"
                                value={OfferData?.TerminationDate || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    TerminationDate: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom17"
                            >
                              <Form.Label>PLI Provision:</Form.Label>
                              <Form.Control
                                type="text"
                                value={OfferData?.ODContract_PLIProvision || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    ODContract_PLIProvision: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>

                            <Form.Group as={Col} md="12" controlId="validationCustom04">
                              <Form.Label>Personal Email:</Form.Label>
                              <Form.Control
                                type="Email"
                                value={OfferData?.PersonalEmail || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    PersonalEmail: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom04">
                              <Form.Label>Business Email:</Form.Label>
                              <Form.Control
                                type="Email"
                                value={OfferData?.BusinessEmail || ""}
                                onChange={(e) =>
                                  setOfferData({
                                    ...OfferData,
                                    BusinessEmail: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                            
                          </div>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div style={{ marginBottom: "-15px", marginTop: "5px" }}>
                <InputGroup className="justify-content-end">
                  <Button
                    type="submit"
                    className="mb-2 btn btn-sm"
                    size="sm"
                    variant="primary"
                  >
                    Save Records
                  </Button>
                  {props.newTitle === undefined && (
                    <Button
                      type="reset"
                      className="mb-2 btn btn-sm"
                      size="sm"
                      variant="warning"
                    >
                      Reset
                    </Button>
                  )}
                  {props.newTitle !== undefined && (
                    <Button
                      type="button"
                      className="mb-2 btn btn-sm"
                      size="sm"
                      variant="danger"
                      onClick={props?.closeModal}
                    >
                      Close
                    </Button>
                  )}
                </InputGroup>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default OfferEntry;
