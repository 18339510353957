import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useCallback } from "react";
import { Button, InputGroup } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { postApi } from "../../../AxiosRequest";
import Swal from "sweetalert2";
import SwalAlert from "../../common/SwalAlert";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../../common/Util";

function ChangePasswordModel(props: any) {
  console.log("🚀 ~ file: ChangePasswordModel.tsx:11 ~ ChangePasswordModel ~ props:", props)
  const [validated, setValidated] = useState(false);
    
  const BackPage = () => {
    window.history.back();
  };
  let navigate = useNavigate();
  function ForceLogout() {
    clearStorage();
    let path = "/Login";
    navigate(path);
  }


  const [UserChangePassword, setUserChangePassword] = useState({
    UserId: props.editData,
    UserName: "",
    OldPassword: "",
    Password: "",
    ConfirmPassword:""
  });
  const handleSubmit = async (event: {
    currentTarget: any;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    
  if(UserChangePassword.ConfirmPassword!==UserChangePassword.Password){
     Swal.fire("Password and Confirm Password should be same.", "", "info");
     return false;
  }
  
    const form = event.currentTarget;
    if (form.checkValidity() === false) event.stopPropagation();
    else {
               
          let param = {
            UserId: UserChangePassword.UserId,
            NewPassword:UserChangePassword.Password,
            OldPassword:UserChangePassword.OldPassword,
            ConfirmNewPassword:UserChangePassword.ConfirmPassword,

          };
          await postApi("/api/auth/ResetPassword",null, param)
            .then(async (response) => {            
              if(response.data=="Success"){
                Swal.fire("Password has been changed successfully, Please login again.", "", "success");
                ForceLogout();
               
              }else{              
              Swal.fire(response.data, "", "success");
              props.handleRefresh();
              }
            })
            .catch(async (error) => {
              SwalAlert(error);
            })
            .finally(async () => {});
      
    }
    setValidated(true);
  };
 const CancelBtn=()=>{
  ForceLogout();
 }
  
  return (
    <Modal
      
      show={props.show}      
      // onHide={BackPage}
      animation={true}
     
    >
      <Modal.Header >
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={ handleSubmit }
        >
          <div className="container">
            <div className="col-lg-12">
           
                  <Form.Group as={Col} md="12" controlId="validationCustom01"  hidden={true}>
                    <Form.Label>User name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={props.editData}
                      placeholder="User name"
                      // onChange={(e) =>
                      //   setUserChangePassword({...UserChangePassword,  UserId: e.target.value})
                      // }
                     
                    />
                  </Form.Group> 
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      value={UserChangePassword.OldPassword}
                      placeholder="Old Password"
                      onChange={(e) =>
                        setUserChangePassword({...UserChangePassword,  OldPassword: e.target.value})
                      }
                    />
                  </Form.Group> 
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      value={UserChangePassword.Password}
                      placeholder="New Password"
                      onChange={(e) =>
                        setUserChangePassword({...UserChangePassword,  Password: e.target.value})
                      }
                    />
                  </Form.Group>  
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      value={UserChangePassword.ConfirmPassword}
                      placeholder="Confirm Password"
                      onChange={(e) =>
                        setUserChangePassword({...UserChangePassword,  ConfirmPassword: e.target.value})
                      }
                    />
                  </Form.Group>
                 
          <div className="mt-2 btn-Model-margin " style={{float:"right"}}>
          
                <InputGroup>
            <Button size="sm" className="btn btn-primary" type="submit">
              Submit
            </Button>            
            <Button size="sm" className=" btn btn-danger"  type="button" onClick={CancelBtn} >
              Cancel
            </Button>
            </InputGroup>
          </div></div>
            </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ChangePasswordModel;
