import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CHeading } from "../../CHeading";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import SwalAlert from "../../common/SwalAlert";
import RoleModel from "../Models/RoleModel";
import { Button } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Loading from "../../common/Loading";
interface IRole {
  RoleID: number;
  RoleName: string;
  CompanyId: number;
  CompanyName: string;
}
const CompanyDetails = () => {
  const [title, setTitle] = useState("");
  const [loading, seteLoading] = useState(false);
  const [editRoleData, seteditRoleData] = useState<IRole[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [show, setShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const [roleData, setRoleData] = useState<IRole[]>([]);
  const [PermissionData, setPermissionData] = useState([]);
  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };

  useEffect(() => {
    async function populateRole() {
      seteLoading(true);
      await getApi("/api/Roles/getallRoleDetails")
        .then((response) => {
          setRoleData(response.data);
          seteLoading(false);
        })
        .catch((error) => {
          
          SwalAlert(error);
        });
    }
    async function populatePermission() {
      seteLoading(true);
      await getApi("/api/Roles/getRoleNPermission")
        .then((response) => {
          console.log("🚀 ~ file: RoleDetails.tsx:53 ~ .then ~ response:", response)
          setPermissionData(response.data);
          seteLoading(false);
        })
        .catch((error) => {
          
          SwalAlert(error);
        });
    }
    populateRole();
    populatePermission();
  }, [refresh]);
  const columnDefs = [
    {
      headerName: "Company Name",
      field: "CompanyName",
      filter: true,
      flex: 1,
    },    
    {
      headerName: "Role Name",
      field: "RoleName",
      filter: true,
      flex: 1,
    },
   
  ];
  const columnotherDefs = [    
    {
      headerName: "Company Name",
      field: "CompanyName",
      filter: true,
      flex: 1,
    },
    {
      headerName: "Role Name",
      field: "RoleName",
      filter: true,
      flex: 1,
    },
    {
      headerName: "Permission Name",
      field: "PermissionName",
      filter: true,
      flex: 1,
    },
   
  ];
  function resetState() {
    const newItems: any = {
      RoleID: 0,
      RoleName: "",
      CompanyId: 0,
      CompanyName: "",
    };
    seteditRoleData(newItems);
  }

  const OpenModel = (obj: any) => {
    if (typeof obj === "undefined") {
      resetState();
      handleShow();
      setIsUpdate(false);
      setTitle("Add Role");
    } else {
      seteditRoleData(obj.data);
      setIsUpdate(true);
      handleShow();
      setTitle("Update Role");
    }
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true
    };
  }, []);

  const Columns = ["CompanyName","RoleName"];
  const ColumnsOther = ["RoleName", "PermissionName", "CompanyName"];
  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title="Role & Permissions" />
        <div className="container-fluid">
          <div className="row">
          <div className="col-md-6">
          {roleData && (
            <CAgGrid
              Columns={Columns}
              rowData={roleData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              OpenModel={OpenModel}
              isShowCreateButton={false}
              isShowExportButton={false}
              isShowQuickFilter={true}
              fileName="RoleDetails"
            />
          )}</div>
          <div className="col-md-6">
          {roleData && (
            <CAgGrid
              Columns={ColumnsOther}
              rowData={PermissionData}
              columnDefs={columnotherDefs}
              defaultColDef={defaultColDef}
              OpenModel={OpenModel}
              isShowCreateButton={false}
              isShowExportButton={false}
              isShowQuickFilter={true}
              fileName="RoleDetails"
            />
          )}</div></div>
        </div>
      </div>
      {show && (
        <RoleModel
          show={show}
          handleClose={handleClose}
          editData={editRoleData}
          seteditRoleData={seteditRoleData}
          isUpdate={isUpdate}
          handleRefresh={handleRefresh}
          title={title}
        />
      )}
    </>
  );
};

export default CompanyDetails;
