import React, { useCallback, useEffect, useMemo, useState } from "react";
import CAgGrid from "../../common/CAgGrid";
import { getApi } from "../../../AxiosRequest";
import { Button } from "react-bootstrap";
import UserModel from "../Models/UserModel";
import { FaAccessibleIcon, FaEdit, FaPatreon, FaTrashAlt } from "react-icons/fa";
import { CHeading } from "../../CHeading";
import SwalAlert from "../../common/SwalAlert";
import Loading from "../../common/Loading";
import Toast from "../../common/Toast";
import { useNavigate } from "react-router-dom";
import { encryptString } from "../../common/cryptoUtils";

interface Iuser {
  UserID: number;
  CompanyID: number;
  CompanyName: string;
  FirstName: string;
  LastName: string;
  LoginId: string;
  MobileNo: string;
  Password: string;
  Active: boolean;
  EmailID: string;
  RoleId: number;
}

const UserDetails = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setuserData] = useState<Iuser[]>([]);
  const [editUserData, seteditUserData] = useState<Iuser[]>([]);
  const [refresh, setrefresh] = useState(0);
  const [show, setShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [message, setMessage] = useState("");
  const [toastKey, setToastKey] = useState<number>(0);

  const handleClick = () => {
    setToastKey((prevKey) => prevKey + 1);
  };


  const handleShow = useCallback(() => {
    setShow(true);
  }, []);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    async function populateUsers() {
      setLoading(true);
      await getApi("/api/Users/getallUserDetails")
        .then((response) => {
          setuserData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          SwalAlert(error);
        });
    }
    let ignore = false;
    if (!ignore) {
      populateUsers();
    }
    return () => {
      ignore = true;
    };
  }, [refresh]);

  const handleRefresh = () => {
    setrefresh((oldkey) => oldkey + 1);
  };
  let navigate = useNavigate();
  const NavigatePermissionView = (obj: any) => { 
     
    let paramUserID = encryptString(obj?.data?.UserID);  
    debugger;
    let paramCompanyID = encryptString(obj?.data?.CompanyID);  
    let paramRoleType = encryptString(obj?.data?.RoleId??0);  
    let path =
      "/UserPermissions/" +
      paramUserID+ "/" +
      paramCompanyID+"/"+
      paramRoleType 
      debugger;
    navigate(path);
  };
  const columnDefs = [
    {
      field: "Action",
      maxWidth: 100,
      cellRenderer: (data: any) => (
        <div>
          <Button
            size="sm"
            className="btn btn-info btnLink"
            onClick={() => OpenModel(data)}
          >
            <i>
              <FaEdit />
            </i>
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "First Name",
      field: "FirstName",
      filter: true,
    },
    {
      headerName: "Last Name",
      field: "LastName",
      filter: true,
    },
    {
      headerName: "Company Name",
      field: "CompanyName",
      filter: true,
    },
    {
      headerName: "Login Id",
      field: "LoginID",
      filter: true,
      resizable: true,
    },
    {
      headerName: "Mobile No",
      field: "MobileNo",
      filter: true
    },
    {
      headerName: "Email Id",
      field: "EmailID",
      filter: true,
    },
    {
      headerName: "Role",
      field: "RoleName",
      filter: true,
    },
    
    {
      field: "View Details",
      maxWidth: 120,
      
      cellRenderer: (data: any) => (
        <div>
          <Button
            style={{ marginLeft: "20px" }}
            size="sm"
            className="btn btn-primary btnLink"
           onClick={() => NavigatePermissionView(data)}
          >
            <FaPatreon />
          </Button>{" "}
        </div>
      ),
    },
    {
      headerName: "Active",
      field: "Active",
      filter: true,
    },
  ];

  function resetState() {
    const newItems: any = {
      UserID: 0,
      FirstName: "",
      LastName: "",
      LoginID: "",
      MobileNo: "",
      CompanyID: 0,
      Password: "",
      EmailID: "",
      Active: true,
      RoleId: 0,
    };
    seteditUserData(newItems);
  }

  const OpenModel = (obj: any) => {
    
    if (typeof obj === "undefined") {
      resetState();
      handleShow();
      setIsUpdate(false);
      setTitle("Add User");
    } else {
      seteditUserData(obj.data);
      setIsUpdate(true);
      handleShow();
      setTitle("Update User");
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const Columns = [
    "FirstName",
    "LastName",
    "CompanyID",
    "LoginID",
    "MobileNo",
    "EmailID",
    "RoleId",
    
  ];

  return (
    <>
      {loading && <Loading />}
      <div className="container-fluid">
        <CHeading Title="Users" />
        <Toast message={message} toastKey={toastKey} />
        <div className="container-fluid">
          {userData && (
            <CAgGrid
              Columns={Columns}
              rowData={userData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              OpenModel={OpenModel}
              isShowCreateButton={true}
              isShowExportButton={true}
              isShowQuickFilter={true}
              fileName="UserDetails"
            />
          )}
        </div>
      </div>
      {show && (
        <UserModel
          show={show}
          handleClose={handleClose}
          editData={editUserData}
          seteditUserData={seteditUserData}
          handleClick={handleClick}
          setMessage={setMessage}
          isUpdate={isUpdate}
          handleRefresh={handleRefresh}
          title={title}
        />
      )}
    </>
  );
};

export default UserDetails;
